define("spaces-view/components/view/event/location", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "models/transforms/page-col-list", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _pageColList, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Container
    @value={{@value.data.container}}
    @deviceSize={{@deviceSize}}
    class="page-col-event-location use-highlights"
    data-name={{@value.name}}
    data-gid={{@value.gid}}
    data-path="{{@path}}.data.container"
    ...attributes
  >
    <View::Base::SourceRecord
      @isEditor={{@isEditor}}
      @path={{@path}}
      @model={{@model}}
      @value={{@value}}
      @onLoad={{this.loaded}}
    >
      {{#if this.textLocation}}
        <TextWithOptions
          @tag="div"
          class="event-location location-text"
          @deviceSize={{@deviceSize}}
          @options={{@value.data.title}}
          data-path="{{@path}}.data.title"
          data-name={{@value.name}}
          data-gid={{@value.gid}}
        >
          <MarkDown @value={{this.textLocation}} />
        </TextWithOptions>
      {{/if}}
  
      {{#if this.featureSource}}
        <div
          class="d-none"
          {{on "props-update" this.updateCardProps}}
          data-name={{@value.name}}
          data-gid={{@value.gid}}
          data-type="card"
          data-editor="card-options"
          data-path="{{@path}}.data.card"
          data-model-type={{@value.data.source.model}}
        />
  
        <View::Base::SourceRecord
          @isEditor={{@isEditor}}
          @model={{@model}}
          @value={{this.featureSource}}
          as |feature|
        >
          <Card::Any
            @value={{this.value}}
            @record={{feature}}
            @deviceSize={{@deviceSize}}
            @path={{@path}}
          />
        </View::Base::SourceRecord>
      {{/if}}
    </View::Base::SourceRecord>
  </Container>
  */
  {
    "id": "wnwCy7ts",
    "block": "[[[8,[39,0],[[24,0,\"page-col-event-location use-highlights\"],[16,\"data-name\",[30,1,[\"name\"]]],[16,\"data-gid\",[30,1,[\"gid\"]]],[16,\"data-path\",[29,[[30,2],\".data.container\"]]],[17,3]],[[\"@value\",\"@deviceSize\"],[[30,1,[\"data\",\"container\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@isEditor\",\"@path\",\"@model\",\"@value\",\"@onLoad\"],[[30,5],[30,2],[30,6],[30,1],[30,0,[\"loaded\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"textLocation\"]],[[[1,\"      \"],[8,[39,3],[[24,0,\"event-location location-text\"],[16,\"data-path\",[29,[[30,2],\".data.title\"]]],[16,\"data-name\",[30,1,[\"name\"]]],[16,\"data-gid\",[30,1,[\"gid\"]]]],[[\"@tag\",\"@deviceSize\",\"@options\"],[\"div\",[30,4],[30,1,[\"data\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@value\"],[[30,0,[\"textLocation\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"featureSource\"]],[[[1,\"      \"],[11,0],[24,0,\"d-none\"],[16,\"data-name\",[30,1,[\"name\"]]],[16,\"data-gid\",[30,1,[\"gid\"]]],[24,\"data-type\",\"card\"],[24,\"data-editor\",\"card-options\"],[16,\"data-path\",[29,[[30,2],\".data.card\"]]],[16,\"data-model-type\",[30,1,[\"data\",\"source\",\"model\"]]],[4,[38,6],[\"props-update\",[30,0,[\"updateCardProps\"]]],null],[12],[13],[1,\"\\n\\n      \"],[8,[39,1],null,[[\"@isEditor\",\"@model\",\"@value\"],[[30,5],[30,6],[30,0,[\"featureSource\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,7],null,[[\"@value\",\"@record\",\"@deviceSize\",\"@path\"],[[30,0,[\"value\"]],[30,7],[30,4],[30,2]]],null],[1,\"\\n      \"]],[7]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@value\",\"@path\",\"&attrs\",\"@deviceSize\",\"@isEditor\",\"@model\",\"feature\"],false,[\"container\",\"view/base/source-record\",\"if\",\"text-with-options\",\"mark-down\",\"div\",\"on\",\"card/any\"]]",
    "moduleName": "spaces-view/components/view/event/location.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let PageColEventLocationComponent = _exports.default = (_class = class PageColEventLocationComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "space", _descriptor2, this);
      _initializerDefineProperty(this, "fastboot", _descriptor3, this);
      _initializerDefineProperty(this, "pageCols", _descriptor4, this);
      _initializerDefineProperty(this, "record", _descriptor5, this);
      _initializerDefineProperty(this, "_card", _descriptor6, this);
      _initializerDefineProperty(this, "_value", _descriptor7, this);
    }
    get textLocation() {
      if (this.record?.location?.type != 'Text') {
        return null;
      }
      return this.record?.location?.value;
    }
    get featureSource() {
      if (this.record?.location?.type != 'Feature') {
        return null;
      }
      return new _pageColList.PageCol({
        data: {
          source: {
            model: 'feature',
            id: this.record?.location?.value
          }
        }
      });
    }
    updateCardProps(event) {
      const value = this.args.value;
      value.data.card = event.detail.value;
      this._card = event.detail.value;
      this._value = value;
    }
    get card() {
      return this._card ?? this.args.value?.data?.card ?? this.args.value?.data;
    }
    get value() {
      return this._value ?? this.args.value;
    }
    loaded(record) {
      this.record = record;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "space", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pageCols", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "record", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_card", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCardProps", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateCardProps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageColEventLocationComponent);
});