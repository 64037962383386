define("manage/components/manage/article-slug", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{@isDisabled}}
    @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    @value={{this.value}}
    ...attributes
  >
    <:view>
      {{#if @value}}
        <p class="view-slug">
          {{@value}}
        </p>
      {{else}}
        <div class="alert alert-info" role="alert">
          {{t "slug-no-value"}}
        </div>
      {{/if}}
    </:view>
  
    <:edit as |manager id|>
      <Input::ArticleSlug @value={{this.value}} @onChange={{this.onChange}} @id={{id}} @teamId={{@teamId}} @onValidate={{this.validate}}/>
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "iu4iWTAr",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"@value\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,0,[\"value\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[10,2],[14,0,\"view-slug\"],[12],[1,\"\\n        \"],[1,[30,6]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"slug-no-value\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],null,[[\"@value\",\"@onChange\",\"@id\",\"@teamId\",\"@onValidate\"],[[30,0,[\"value\"]],[30,0,[\"onChange\"]],[30,8],[30,9],[30,0,[\"validate\"]]]],null],[1,\"\\n  \"]],[7,8]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@value\",\"manager\",\"id\",\"@teamId\"],false,[\"manage/field-container\",\":view\",\"if\",\"p\",\"div\",\"t\",\":edit\",\"input/article-slug\"]]",
    "moduleName": "manage/components/manage/article-slug.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageArticleSlugComponent = _exports.default = (_class = class ManageArticleSlugComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
      _initializerDefineProperty(this, "_validate", _descriptor2, this);
    }
    get value() {
      if (typeof this._value == 'string') {
        return this._value;
      }
      return this.args.value;
    }
    get isDisabled() {
      return typeof this._value != 'string' || this._validate;
    }
    validate(value) {
      this._validate = value;
    }
    onChange(value) {
      this._value = value;
    }
    save() {
      this.args.onSave?.(this.value);
    }
    cancel() {
      this._value = null;
      this._validate = null;
      this.args.onCancel?.();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_validate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageArticleSlugComponent);
});