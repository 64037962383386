define("ember-table/components/ember-th/sort-indicator/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isSorted}}
    <span data-test-sort-indicator class="et-sort-indicator {{if this.isSortedAsc 'is-ascending' 'is-descending'}}">
      {{#if (has-block)}}
        {{yield this.columnMeta}}
      {{else}}
        {{#if this.isMultiSorted}}
          {{this.sortIndex}}
        {{/if}}
      {{/if}}
    </span>
  {{/if}}
  
  {{#if this.isSortable}}
    <button data-test-sort-toggle class="et-sort-toggle et-speech-only">Toggle Sort</button>
  {{/if}}
  
  */
  {
    "id": "RgPX+aq+",
    "block": "[[[41,[30,0,[\"isSorted\"]],[[[1,\"  \"],[10,1],[15,0,[29,[\"et-sort-indicator \",[52,[30,0,[\"isSortedAsc\"]],\"is-ascending\",\"is-descending\"]]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[30,0,[\"columnMeta\"]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isMultiSorted\"]],[[[1,\"        \"],[1,[30,0,[\"sortIndex\"]]],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isSortable\"]],[[[1,\"  \"],[10,\"button\"],[14,0,\"et-sort-toggle et-speech-only\"],[12],[1,\"Toggle Sort\"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"span\",\"has-block\",\"yield\",\"button\"]]",
    "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs",
    "isStrictMode": false
  });
});