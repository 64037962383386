define("spaces-view/components/view/event/schedule", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord class="use-highlights" @isEditor={{@isEditor}} @path={{@path}} @model={{@model}} @value={{@value}} as |event|>
    <Schedule @value={{event.entries}} @deviceSize={{@deviceSize}} @options={{@value.data}} @name={{@value.name}} @gid={{@value.gid}} @path="{{@path}}.data"/>
  </View::Base::SourceRecord>
  */
  {
    "id": "vvUzVV4W",
    "block": "[[[8,[39,0],[[24,0,\"use-highlights\"]],[[\"@isEditor\",\"@path\",\"@model\",\"@value\"],[[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@deviceSize\",\"@options\",\"@name\",\"@gid\",\"@path\"],[[30,5,[\"entries\"]],[30,6],[30,4,[\"data\"]],[30,4,[\"name\"]],[30,4,[\"gid\"]],[29,[[30,2],\".data\"]]]],null],[1,\"\\n\"]],[5]]]]]],[\"@isEditor\",\"@path\",\"@model\",\"@value\",\"event\",\"@deviceSize\"],false,[\"view/base/source-record\",\"schedule\"]]",
    "moduleName": "spaces-view/components/view/event/schedule.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class ViewEventScheduleComponent extends _component2.default {}
  _exports.default = ViewEventScheduleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewEventScheduleComponent);
});