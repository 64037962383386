define("manage/components/manage/cover", ["exports", "@ember/component", "@glimmer/component", "@ember/template", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _template, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{@isDisabled}}
    @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    ...attributes
  >
    <:view>
      {{#if @value}}
        <div class="manage-cover-view" style={{this.imageStyle}}></div>
      {{else}}
        <div class="alert alert-danger" role="alert">
          {{t "message.no-cover"}}
        </div>
      {{/if}}
    </:view>
  
    <:edit as |id|>
      <Input::Picture
          @id={{id}}
          @model={{@model}}
          @modelId={{@modelId}}
          class="mb-3 square-cover"
          @value={{@value}}
          @onChange={{this.changeValue}}
        />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "uDfDoNXa",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[10,0],[14,0,\"manage-cover-view\"],[15,5,[30,0,[\"imageStyle\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-danger\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"message.no-cover\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"mb-3 square-cover\"]],[[\"@id\",\"@model\",\"@modelId\",\"@value\",\"@onChange\"],[[30,7],[30,8],[30,9],[30,6],[30,0,[\"changeValue\"]]]],null],[1,\"\\n  \"]],[7]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@value\",\"id\",\"@model\",\"@modelId\"],false,[\"manage/field-container\",\":view\",\"if\",\"div\",\"t\",\":edit\",\"input/picture\"]]",
    "moduleName": "manage/components/manage/cover.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageCoverComponent = _exports.default = (_class = class ManageCoverComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    get imageStyle() {
      if (!this.args.value) {
        return (0, _template.htmlSafe)('');
      }
      const picture = this.args.value?.get('picture');
      let rnd = '';
      if (!picture.startsWith('data:')) {
        rnd = '/lg?rnd=' + Math.random();
      }
      if (!picture) {
        return (0, _template.htmlSafe)('');
      }
      return (0, _template.htmlSafe)(`background-image: url('${picture}${rnd}')`);
    }
    get isDisabled() {
      return !this.value;
    }
    async save() {
      await this.value.save();
      return this.args.onSave?.(this.value);
    }
    async cancel() {
      this.value = null;
      return this.args.onCancel?.();
    }
    changeValue(_, value) {
      this.value = value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageCoverComponent);
});