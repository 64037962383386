define("spaces/components/editor/content-picker", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Frame class="editor-content-picker" @title={{capitalize (t "option-panel.content-picker")}}>
    <Editor::Properties::OptionContainer @label={{t "option-panel.mode"}} as |id|>
      <Input::Select class="input-mode" id={{id}} @list={{this.availableModes}} @value={{this.mode}} @onChange={{fn this.change "mode"}} />
    </Editor::Properties::OptionContainer>
  
    {{#if (eq this.mode "paragraph")}}
      <Editor::Properties::OptionContainer @label={{t "option-panel.paragraph-index"}} as |id|>
        <Input::Text @value={{this.paragraphIndex}} id={{id}} class="form-control text-paragraph-index" @onChange={{fn this.change "paragraphIndex"}} />
      </Editor::Properties::OptionContainer>
    {{/if}}
  </Input::Options::Frame>
  */
  {
    "id": "u1XR6HWO",
    "block": "[[[8,[39,0],[[24,0,\"editor-content-picker\"]],[[\"@title\"],[[28,[37,1],[[28,[37,2],[\"option-panel.content-picker\"],null]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@label\"],[[28,[37,2],[\"option-panel.mode\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"input-mode\"],[16,1,[30,1]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,0,[\"availableModes\"]],[30,0,[\"mode\"]],[28,[37,5],[[30,0,[\"change\"]],\"mode\"],null]]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\\n\"],[41,[28,[37,7],[[30,0,[\"mode\"]],\"paragraph\"],null],[[[1,\"    \"],[8,[39,3],null,[[\"@label\"],[[28,[37,2],[\"option-panel.paragraph-index\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,8],[[16,1,[30,2]],[24,0,\"form-control text-paragraph-index\"]],[[\"@value\",\"@onChange\"],[[30,0,[\"paragraphIndex\"]],[28,[37,5],[[30,0,[\"change\"]],\"paragraphIndex\"],null]]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"id\",\"id\"],false,[\"input/options/frame\",\"capitalize\",\"t\",\"editor/properties/option-container\",\"input/select\",\"fn\",\"if\",\"eq\",\"input/text\"]]",
    "moduleName": "spaces/components/editor/content-picker.hbs",
    "isStrictMode": false
  });
  let EditorContentPickerComponent = _exports.default = (_class = class EditorContentPickerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "availableModes", ["all", "paragraph"]);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value ?? {};
    }
    get mode() {
      return this.value.mode ?? "all";
    }
    get paragraphIndex() {
      return this.value.paragraphIndex ?? 0;
    }
    change(field, value) {
      if (!this._value) {
        this._value = this.value;
      }
      this._value[field] = value;
      return this.args.onChange(this.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorContentPickerComponent);
});