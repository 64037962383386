define("manage/components/input/time-tracking", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row" ...attributes>
    <div class="col col-12 col-md-3 mb-3">
      <div class="form-floating">
        <Label @value="hours" @after={{true}} as |id|>
          <Input @value={{this.hours}} @type="number" class="form-control value-hours" step="0.5" id={{id}} />
        </Label>
      </div>
    </div>
    <div class="col mb-3">
      <Input::Date class="value-date" @allowUnset={{false}} @allowDate={{true}} @allowTime={{true}}
        @value={{this.value.date}} @onChange={{this.changeDate}} />
    </div>
  
    <div class="col-12">
      <div class="form-floating">
        <Label @value="details" @after={{true}} as |id|>
          <Input @value={{this.details}} class="form-control value-details" id={{id}} />
        </Label>
      </div>
    </div>
  </div>
  */
  {
    "id": "V+uTeksT",
    "block": "[[[11,0],[24,0,\"row\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col col-12 col-md-3 mb-3\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-floating\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@value\",\"@after\"],[\"hours\",true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,0,\"form-control value-hours\"],[24,\"step\",\"0.5\"],[16,1,[30,2]]],[[\"@value\",\"@type\"],[[30,0,[\"hours\"]],\"number\"]],null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col mb-3\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"value-date\"]],[[\"@allowUnset\",\"@allowDate\",\"@allowTime\",\"@value\",\"@onChange\"],[false,true,true,[30,0,[\"value\",\"date\"]],[30,0,[\"changeDate\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-floating\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@value\",\"@after\"],[\"details\",true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,0,\"form-control value-details\"],[16,1,[30,3]]],[[\"@value\"],[[30,0,[\"details\"]]]],null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"id\",\"id\"],false,[\"div\",\"label\",\"input\",\"input/date\"]]",
    "moduleName": "manage/components/input/time-tracking.hbs",
    "isStrictMode": false
  });
  let InputTimeTrackingComponent = _exports.default = (_class = class InputTimeTrackingComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value ?? {};
    }
    get hours() {
      return this.value.hours;
    }
    set hours(value) {
      this.change('hours', value);
    }
    get date() {
      return this.value.date;
    }
    get details() {
      return this.value.details;
    }
    set details(value) {
      this.change('details', value);
    }
    changeDate(date) {
      return this.change('date', date);
    }
    change(name, value) {
      if (!this._value) {
        this._value = this.value;
      }
      this._value[name] = value;
      return this.args.onChange?.(this._value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputTimeTrackingComponent);
});