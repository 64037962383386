define("manage/components/manage/other-answers", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{@onSave}}
    @onCancel={{@onCancel}}
    @value={{@value}}
    ...attributes
  >
    <:view>
      {{#if this.questions}}
        <AnswerList @value={{@value}} @questions={{this.questions}} />
      {{else}}
        <div class="alert alert-info" role="alert">
          {{t "answer-message.no-generic-questions"}}
        </div>
      {{/if}}
    </:view>
  
    <:edit as |manager|>
      <Input::QuestionList @questions={{this.questions}} @value={{manager.value}} @onChange={{manager.change}} />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "6xbIxhaW",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"@value\"],[[30,2],[30,0,[\"isDisabled\"]],[30,3],[30,4],[30,5],[30,6],[30,7]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,0,[\"questions\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@value\",\"@questions\"],[[30,7],[30,0,[\"questions\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"answer-message.no-generic-questions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],null,[[\"@questions\",\"@value\",\"@onChange\"],[[30,0,[\"questions\"]],[30,8,[\"value\"]],[30,8,[\"change\"]]]],null],[1,\"\\n  \"]],[8]]]]]],[\"&attrs\",\"@title\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"@value\",\"manager\"],false,[\"manage/field-container\",\":view\",\"if\",\"answer-list\",\"div\",\"t\",\":edit\",\"input/question-list\"]]",
    "moduleName": "manage/components/manage/other-answers.hbs",
    "isStrictMode": false
  });
  const defaultNames = ['position', 'name', 'description', 'icons', 'pictures', 'sounds'];
  class ManageOtherAnswersComponent extends _component2.default {
    get questions() {
      return this.args.questions?.filter(a => !defaultNames.includes(a.name)) ?? [];
    }
    get isDisabled() {
      return this.args.isDisabled || this.questions.length == 0;
    }
  }
  _exports.default = ManageOtherAnswersComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageOtherAnswersComponent);
});