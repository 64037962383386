define("map/components/map-geojson-feature", ["exports", "@ember/component", "@glimmer/component", "ol/proj", "@ember/service", "@ember/object", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _proj, _service, _object, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.pointLocation}}
    <MapInteractive @userLocation={{this.position.center}} class="map-geo-json" as | map |>
      <Map::Overlay class="zero" @map={{map}} @position={{this.pointLocation}}>
        <PointIcon />
        <div class="d-none" {{did-insert (fn this.setupPoint map)}} {{did-update (fn this.setupPoint map) @value}}></div>
      </Map::Overlay>
    </MapInteractive>
  {{else}}
    <div class="d-flex map-geo-json">
      <div class="message text-center">
        <FaIcon @icon="circle-dot" @size="3x" class="mb-3"/>
        <p>{{@noValueMessage}}</p>
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "pNoYKHR2",
    "block": "[[[41,[30,0,[\"pointLocation\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"map-geo-json\"]],[[\"@userLocation\"],[[30,0,[\"position\",\"center\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"zero\"]],[[\"@map\",\"@position\"],[[30,1],[30,0,[\"pointLocation\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n      \"],[11,0],[24,0,\"d-none\"],[4,[38,5],[[28,[37,6],[[30,0,[\"setupPoint\"]],[30,1]],null]],null],[4,[38,7],[[28,[37,6],[[30,0,[\"setupPoint\"]],[30,1]],null],[30,2]],null],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"d-flex map-geo-json\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"message text-center\"],[12],[1,\"\\n      \"],[8,[39,8],[[24,0,\"mb-3\"]],[[\"@icon\",\"@size\"],[\"circle-dot\",\"3x\"]],null],[1,\"\\n      \"],[10,2],[12],[1,[30,3]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"map\",\"@value\",\"@noValueMessage\"],false,[\"if\",\"map-interactive\",\"map/overlay\",\"point-icon\",\"div\",\"did-insert\",\"fn\",\"did-update\",\"fa-icon\",\"p\"]]",
    "moduleName": "map/components/map-geojson-feature.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let MapGeojsonFeatureComponent = _exports.default = (_class = class MapGeojsonFeatureComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "position", _descriptor, this);
    }
    get pointLocation() {
      if (this.args.value?.type != 'Point') {
        return false;
      }
      return (0, _proj.transform)(this.args.value.coordinates, 'EPSG:4326', 'EPSG:3857');
    }
    setupPoint(map) {
      (0, _runloop.later)(() => {
        if (!this.pointLocation) {
          return;
        }
        map.getView().setCenter(this.pointLocation);
        map.getView().setZoom(18);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "position", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupPoint", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupPoint"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapGeojsonFeatureComponent);
});