define("spaces/components/editor/button-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/array", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _array, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @value as |buttonItem index|}}
    <div class="button-section">
      <Editor::Properties::ButtonContentOptions @space={{@space}} @value={{buttonItem}} @onChange={{fn this.buttonChanged index}} />
  
      <Editor::Properties::ButtonStyleOptions @deviceSize={{@deviceSize}} @onDeviceSizeChange={{@onDeviceSizeChange}} @value={{buttonItem.classes}} @onChange={{fn this.buttonOptionsChanged index}} />
  
      <div class="mt-3">
        <button class="btn btn-danger btn-delete-button btn-sm" type="button" {{on "click" (fn this.deleteButton index)}}>
          <FaIcon @icon="trash" />
          {{t "delete"}}
        </button>
      </div>
    </div>
    <hr />
  {{/each}}
  
  <button class="btn btn-primary btn-add-button btn-sm" type="button" {{on "click" this.addButton}}>
    <FaIcon @icon="plus" />
    {{t "button"}}
  </button>
  
  */
  {
    "id": "b8tKkPoX",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"button-section\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@space\",\"@value\",\"@onChange\"],[[30,4],[30,2],[28,[37,4],[[30,0,[\"buttonChanged\"]],[30,3]],null]]],null],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@deviceSize\",\"@onDeviceSizeChange\",\"@value\",\"@onChange\"],[[30,5],[30,6],[30,2,[\"classes\"]],[28,[37,4],[[30,0,[\"buttonOptionsChanged\"]],[30,3]],null]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"mt-3\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-danger btn-delete-button btn-sm\"],[24,4,\"button\"],[4,[38,7],[\"click\",[28,[37,4],[[30,0,[\"deleteButton\"]],[30,3]],null]],null],[12],[1,\"\\n        \"],[8,[39,8],null,[[\"@icon\"],[\"trash\"]],null],[1,\"\\n        \"],[1,[28,[35,9],[\"delete\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n\"]],[2,3]],null],[1,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-primary btn-add-button btn-sm\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,0,[\"addButton\"]]],null],[12],[1,\"\\n  \"],[8,[39,8],null,[[\"@icon\"],[\"plus\"]],null],[1,\"\\n  \"],[1,[28,[35,9],[\"button\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@value\",\"buttonItem\",\"index\",\"@space\",\"@deviceSize\",\"@onDeviceSizeChange\"],false,[\"each\",\"-track-array\",\"div\",\"editor/properties/button-content-options\",\"fn\",\"editor/properties/button-style-options\",\"button\",\"on\",\"fa-icon\",\"t\",\"hr\"]]",
    "moduleName": "spaces/components/editor/button-list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let EditorButtonListComponent = _exports.default = (_class2 = class EditorButtonListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_buttons", _descriptor5, this);
    }
    get buttons() {
      if (this._buttons) {
        return this._buttons;
      }
      return (0, _array.A)((this.args.value ?? []).map(a => new ButtonOptions(a)));
    }
    triggerChange(buttons) {
      this._buttons = buttons;
      this.args.onChange?.(buttons.map(a => a.toJSON()));
    }
    buttonOptionsChanged(index, value) {
      const buttons = this.buttons;
      buttons[index]._classes = value;
      this.triggerChange(buttons);
    }
    buttonChanged(index, value) {
      const buttons = this.buttons;
      const classes = buttons[index]?.classes ?? [];
      buttons[index] = new ButtonOptions({
        ...value,
        classes
      });
      this.triggerChange(buttons);
    }
    deleteButton(index) {
      const buttons = this.buttons;
      buttons.removeAt(index);
      this.args.triggerChange(buttons);
    }
    addButton() {
      const buttons = this.buttons;
      buttons.pushObject(new ButtonOptions({
        name: "",
        link: "",
        classes: ["btn-primary"]
      }, () => {
        this.triggerChange(buttons);
      }));
      this.triggerChange(buttons);
    }
  }, (_descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "_buttons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "buttonOptionsChanged", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonOptionsChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "buttonChanged", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteButton", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteButton"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addButton", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addButton"), _class2.prototype)), _class2);
  let ButtonOptions = (_class = class ButtonOptions {
    constructor(button, change) {
      _initializerDefineProperty(this, "_name", _descriptor, this);
      _initializerDefineProperty(this, "_link", _descriptor2, this);
      _initializerDefineProperty(this, "_classes", _descriptor3, this);
      _initializerDefineProperty(this, "_newTab", _descriptor4, this);
      this.change = change;
      this._name = button.name;
      this._link = button.link;
      this._type = button.type ?? "default";
      this._storeType = button.storeType ?? "apple";
      this._classes = button.classes;
      this._newTab = button.newTab;
    }
    get name() {
      return this._name;
    }
    set name(value) {
      this._name = value;
      this.change();
    }
    get newTab() {
      return this._newTab ?? false;
    }
    set newTab(value) {
      this._newTab = value;
      this.change();
    }
    get link() {
      return this._link;
    }
    set link(value) {
      this._link = value;
      this.change();
    }
    get type() {
      return this._type;
    }
    set type(value) {
      this._type = value;
      this.change();
    }
    get storeType() {
      return this._storeType;
    }
    set storeType(value) {
      this._storeType = value;
      this.change();
    }
    get classes() {
      return this._classes;
    }
    set classes(value) {
      this._classes = value;
      this.change();
    }
    toJSON() {
      return {
        name: this.name,
        link: this.link,
        newTab: this.newTab,
        type: this.type,
        storeType: this.storeType,
        classes: this._classes
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_link", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_classes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_newTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorButtonListComponent);
});