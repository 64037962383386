define("manage/components/input/article-slug", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _object, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="input-group {{if this.validation 'has-validation'}}" {{did-insert this.setup}} ...attributes>
    {{! template-lint-disable require-input-label }}
    <Input class="input-article-slug form-control {{if this.validation 'is-invalid'}}" @value={{this.value}} />
  
    {{#if this.loading}}
      <span class="input-group-text" id="addon-wrapping">
        <div class="spinner-border text-primary spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </span>
    {{/if}}
  
    {{#if this.validation}}
      <div class="invalid-feedback">
        {{t this.validation}}
        {{t "slug-generic-invalid"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "srr/FF/a",
    "block": "[[[11,0],[16,0,[29,[\"input-group \",[52,[30,0,[\"validation\"]],\"has-validation\"]]]],[17,1],[4,[38,2],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n\"],[1,\"  \"],[8,[39,3],[[16,0,[29,[\"input-article-slug form-control \",[52,[30,0,[\"validation\"]],\"is-invalid\"]]]]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"    \"],[10,1],[14,0,\"input-group-text\"],[14,1,\"addon-wrapping\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"spinner-border text-primary spinner-border-sm\"],[14,\"role\",\"status\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"visually-hidden\"],[12],[1,\"Loading...\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"validation\"]],[[[1,\"    \"],[10,0],[14,0,\"invalid-feedback\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[30,0,[\"validation\"]]],null]],[1,\"\\n      \"],[1,[28,[35,5],[\"slug-generic-invalid\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"div\",\"if\",\"did-insert\",\"input\",\"span\",\"t\"]]",
    "moduleName": "manage/components/input/article-slug.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let InputArticleSlugComponent = _exports.default = (_class = class InputArticleSlugComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "_value", _descriptor2, this);
      _initializerDefineProperty(this, "loading", _descriptor3, this);
      _initializerDefineProperty(this, "article", _descriptor4, this);
    }
    get timeout() {
      return this.args.timeout ?? 1000;
    }
    get value() {
      if (typeof this._value == 'string') {
        return this._value;
      }
      return this.args.value ?? '';
    }
    set value(newValue) {
      this._value = newValue;
      (0, _runloop.debounce)(this, this.validate, this.timeout);
      this.args.onChange?.(this.value);
      this.args.onValidate?.(this.validation);
    }
    get validation() {
      if (!this.value) {
        return;
      }
      if (this.value.indexOf(' ') != -1) {
        return 'slug-no-space';
      }
      if (this.value.indexOf('--') != -1) {
        return 'slug-double-dash';
      }
      if (this.value.toLowerCase() != this.value) {
        return 'slug-no-uppercase';
      }
      if (!this.value.match(/^[0-9a-z\-]+$/)) {
        return 'slug-invalid-chars';
      }
      if (this.article?.slug == this.value) {
        return 'slug-taken';
      }
      return '';
    }
    setup() {
      this.initialValue = this.args.value;
    }
    async validate() {
      if (this.isDestroying) {
        return;
      }
      if (!this.value || this.value == this.initialValue) {
        this.article = null;
        this.args.onValidate?.(this.validation);
        return;
      }
      const id = this.value;
      const props = {
        id
      };
      if (this.args.teamId) {
        props.team = this.args.teamId;
      }
      let result;
      try {
        this.loading++;
        result = await this.store.queryRecord('article', props);
      } catch (err) {
        result = null;
      } finally {
        this.loading--;
      }
      if (id == this.value) {
        this.article = result;
      }
      this.args.onValidate?.(this.validation);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "article", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputArticleSlugComponent);
});