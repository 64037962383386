define("manage/components/dropdown-theme", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DropdownList class="dropdown-theme" @isActive={{@isActive}} @icon={{this.icon}}>
    <button class="dropdown-item dropdown-item-auto" type="button" {{on "click" (fn this.setTheme "auto")}}>
      <FaIcon @icon="circle-half-stroke" /> {{capitalize (t "auto")}}
    </button>
  
    <button class="dropdown-item dropdown-item-light" type="button" {{on "click" (fn this.setTheme "light")}}>
      <FaIcon @icon="sun" /> {{capitalize (t "light")}}
    </button>
  
    <button class="dropdown-item dropdown-item-dark" type="button" {{on "click" (fn this.setTheme "dark")}}>
      <FaIcon @icon="moon" /> {{capitalize (t "dark")}}
    </button>
  </DropdownList>
  */
  {
    "id": "e3JCt+tf",
    "block": "[[[8,[39,0],[[24,0,\"dropdown-theme\"]],[[\"@isActive\",\"@icon\"],[[30,1],[30,0,[\"icon\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"dropdown-item dropdown-item-auto\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"setTheme\"]],\"auto\"],null]],null],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@icon\"],[\"circle-half-stroke\"]],null],[1,\" \"],[1,[28,[35,5],[[28,[37,6],[\"auto\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[24,0,\"dropdown-item dropdown-item-light\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"setTheme\"]],\"light\"],null]],null],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@icon\"],[\"sun\"]],null],[1,\" \"],[1,[28,[35,5],[[28,[37,6],[\"light\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"button\"],[24,0,\"dropdown-item dropdown-item-dark\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"setTheme\"]],\"dark\"],null]],null],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@icon\"],[\"moon\"]],null],[1,\" \"],[1,[28,[35,5],[[28,[37,6],[\"dark\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@isActive\"],false,[\"dropdown-list\",\"button\",\"on\",\"fn\",\"fa-icon\",\"capitalize\",\"t\"]]",
    "moduleName": "manage/components/dropdown-theme.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let DropdownThemeComponent = _exports.default = (_class = class DropdownThemeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "theme", _descriptor, this);
      _defineProperty(this, "icons", {
        auto: 'circle-half-stroke',
        light: 'sun',
        dark: 'moon'
      });
    }
    get icon() {
      return this.icons[this.theme.preferred];
    }
    setTheme(name) {
      this.theme.preferred = name;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "theme", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setTheme", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setTheme"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownThemeComponent);
});