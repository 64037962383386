define("manage/components/manage/color-palette", ["exports", "@ember/component", "@glimmer/component", "models/transforms/color-palette", "@ember/template", "@ember/object/internals", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _colorPalette, _template, _internals, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}} @onEdit={{@onEdit}} @onSave={{this.save}} @onCancel={{this.cancel}} {{did-insert this.setup}} class="manage-color-palette" ...attributes>
    <:view>
      {{#each this.colors as |color|}}
        <div class="color d-inline-block m-2">
          <label class="form-label color-name" for="{{this.elementId}}-{{color.name}}">{{t color.name}}</label>
          <div class="color-block color-{{color.name}} text-light rounded-3" style={{color.style}}>
            {{color.value}}
          </div>
        </div>
      {{/each}}
    </:view>
  
    <:edit>
      {{#each this.colors as |color|}}
        <div class="color d-inline-block m-2">
          <label class="form-label color-name" for="{{this.elementId}}-{{color.name}}">{{t color.name}}</label>
          <Input::Text @type="color" class="form-control form-control-color input-color-{{color.name}}"
            id="{{this.elementId}}-{{color.name}}" @value={{color.value}} {{on "input" (fn this.colorInput color.name)}}
            @onChange={{fn this.change color.name}} />
        </div>
      {{/each}}
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "aSHrXAhU",
    "block": "[[[8,[39,0],[[24,0,\"manage-color-palette\"],[17,1],[4,[38,1],[[30,0,[\"setup\"]]],null]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"colors\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"color d-inline-block m-2\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"form-label color-name\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"-\",[30,6,[\"name\"]]]]],[12],[1,[28,[35,7],[[30,6,[\"name\"]]],null]],[13],[1,\"\\n        \"],[10,0],[15,0,[29,[\"color-block color-\",[30,6,[\"name\"]],\" text-light rounded-3\"]]],[15,5,[30,6,[\"style\"]]],[12],[1,\"\\n          \"],[1,[30,6,[\"value\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[6]],null],[1,\"  \"]],[]],[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"colors\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"color d-inline-block m-2\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"form-label color-name\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"-\",[30,7,[\"name\"]]]]],[12],[1,[28,[35,7],[[30,7,[\"name\"]]],null]],[13],[1,\"\\n        \"],[8,[39,9],[[16,0,[29,[\"form-control form-control-color input-color-\",[30,7,[\"name\"]]]]],[16,1,[29,[[30,0,[\"elementId\"]],\"-\",[30,7,[\"name\"]]]]],[4,[38,11],[\"input\",[28,[37,10],[[30,0,[\"colorInput\"]],[30,7,[\"name\"]]],null]],null]],[[\"@type\",\"@value\",\"@onChange\"],[\"color\",[30,7,[\"value\"]],[28,[37,10],[[30,0,[\"change\"]],[30,7,[\"name\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[7]],null],[1,\"  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"color\",\"color\"],false,[\"manage/field-container\",\"did-insert\",\":view\",\"each\",\"-track-array\",\"div\",\"label\",\"t\",\":edit\",\"input/text\",\"fn\",\"on\"]]",
    "moduleName": "manage/components/manage/color-palette.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ColorItem = (_class = class ColorItem {
    constructor(name, value) {
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
      this.name = name;
      this.value = value;
    }
    get style() {
      return (0, _template.htmlSafe)(`background-color: ${this.value}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let ManageColorPaletteComponent = _exports.default = (_class2 = class ManageColorPaletteComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "elementId", 'color-palette-' + (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "_value", _descriptor3, this);
      _initializerDefineProperty(this, "colors", _descriptor4, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value ?? new _colorPalette.ColorPalette();
    }
    setup() {
      const result = [];
      for (const color of _colorPalette.standardColors) {
        result.push(new ColorItem(color, this.value[color]));
      }
      this.colors = result;
    }
    change(name, value) {
      const color = this.colors.find(a => a.name == name);
      color.value = value;
      if (!this._value) {
        this._value = new _colorPalette.ColorPalette(this.value);
      }
      this._value[name] = value;
    }
    colorInput(name, event) {
      this.change(name, event?.target?.value);
    }
    save() {
      return this.args.onSave?.(this.value);
    }
    cancel() {
      this._value = null;
      return this.args.onCancel?.();
    }
  }, (_descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "colors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "change"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "colorInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "colorInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype)), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageColorPaletteComponent);
});