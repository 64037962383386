define("spaces/components/editor/link", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Panel class="link-named" @config={{this.config}} @value={{@value}} @space={{@space}} @onChange={{@onChange}} />
  */
  {
    "id": "xmYXK66X",
    "block": "[[[8,[39,0],[[24,0,\"link-named\"]],[[\"@config\",\"@value\",\"@space\",\"@onChange\"],[[30,0,[\"config\"]],[30,1],[30,2],[30,3]]],null]],[\"@value\",\"@space\",\"@onChange\"],false,[\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/link.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorLinkComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        name: "link",
        type: "link",
        label: "destination"
      }, {
        name: "newTab",
        type: "bool",
        label: "open in new tab"
      }]);
    }
  }
  _exports.default = EditorLinkComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorLinkComponent);
});