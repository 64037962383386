define("manage/components/manage/selectable-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/array", "@glimmer/tracking", "@ember/service", "@ember/test-waiters", "@ember/template-factory"], function (_exports, _component, _component2, _object, _array, _tracking, _service, _testWaiters, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canSelectAll}}
    <div class="selectable-list-selection-tools">
      <div class="form-check">
        <Input class="form-check-input chk-select-all" @type="checkbox" @checked={{this.allSelected}} id="select-all-records" />
  
        <label class="form-check-label" for="select-all-records">
          {{t "select all"}}
        </label>
      </div>
    </div>
  {{/if}}
  
  {{#if this.isEmpty}}
    <div class="alert alert-info flex-fill mx-3" role="alert">
      {{t "message-selectable-list-empty"}}
    </div>
  {{/if}}
  
  {{#each this.value as |item|}}
    {{yield item this.selection this.onSelect}}
  {{/each}}
  
  <LazyListLoadMore @lazyList={{@value}} class="load-more" />
  
  {{#if this.selection}}
    <Manage::ActionsSelected @actions={{this.actions}} @selected={{this.selection}} @onAction={{this.action}} @onDelete={{this.delete}} class="w-100"/>
  {{/if}}
  
  */
  {
    "id": "pghZ2vIM",
    "block": "[[[41,[30,0,[\"canSelectAll\"]],[[[1,\"  \"],[10,0],[14,0,\"selectable-list-selection-tools\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"form-check-input chk-select-all\"],[24,1,\"select-all-records\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"allSelected\"]]]],null],[1,\"\\n\\n      \"],[10,\"label\"],[14,0,\"form-check-label\"],[14,\"for\",\"select-all-records\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"select all\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isEmpty\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-info flex-fill mx-3\"],[14,\"role\",\"alert\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"message-selectable-list-empty\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"value\"]]],null]],null],null,[[[1,\"  \"],[18,3,[[30,1],[30,0,[\"selection\"]],[30,0,[\"onSelect\"]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[8,[39,8],[[24,0,\"load-more\"]],[[\"@lazyList\"],[[30,2]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"selection\"]],[[[1,\"  \"],[8,[39,9],[[24,0,\"w-100\"]],[[\"@actions\",\"@selected\",\"@onAction\",\"@onDelete\"],[[30,0,[\"actions\"]],[30,0,[\"selection\"]],[30,0,[\"action\"]],[30,0,[\"delete\"]]]],null],[1,\"\\n\"]],[]],null]],[\"item\",\"@value\",\"&default\"],false,[\"if\",\"div\",\"input\",\"label\",\"t\",\"each\",\"-track-array\",\"yield\",\"lazy-list-load-more\",\"manage/actions-selected\"]]",
    "moduleName": "manage/components/manage/selectable-list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  let waiter = (0, _testWaiters.buildWaiter)('selectable-list-waiter');
  let ManageSelectableListComponent = _exports.default = (_class = class ManageSelectableListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "selection", _descriptor3, this);
    }
    get allValues() {
      if (Array.isArray(this.args.value?.items)) {
        return this.args.value.items;
      }
      return this.args.value ?? [];
    }
    get allSelected() {
      return this.selection == 'all';
    }
    set allSelected(value) {
      this.selection = value ? 'all' : (0, _array.A)();
    }
    get canSelectAll() {
      return this.allValues.length > 1 || this.selection == 'all';
    }
    get selectedValues() {
      if (this.allSelected) {
        return this.allValues;
      }
      return this.selection?.map?.(a => this.allValues.find(b => b.id == a))?.filter?.(a => a) ?? [];
    }
    toActionObject(name) {
      if (name == 'publish') {
        return {
          name: this.intl.t('publish'),
          key: 'publish',
          icon: 'eye',
          class: 'btn-secondary'
        };
      }
      if (name == 'unpublish') {
        return {
          name: this.intl.t('unpublish'),
          key: 'unpublish',
          icon: 'eye-slash',
          class: 'btn-secondary'
        };
      }
      if (name == 'pending') {
        return {
          name: this.intl.t('set as pending'),
          key: 'pending',
          icon: 'clock',
          class: 'btn-secondary'
        };
      }
    }
    get actions() {
      const result = [];
      for (const value of this.selectedValues) {
        for (const action of value.availableActions ?? []) {
          if (!result.includes(action)) {
            result.push(action);
          }
        }
      }
      return result.map(a => this.toActionObject(a)).filter(a => a);
    }
    get firstRecord() {
      return this.allValues[0];
    }
    get modelName() {
      return this.firstRecord?.constructor?.modelName;
    }
    get value() {
      let result = this.args.value ?? [];
      if (Array.isArray(this.args.value?.items)) {
        result = this.args.value.items;
      }
      return result.filter(a => !a.isDeleted);
    }
    get isEmpty() {
      return !this.value?.length;
    }
    async doWithWaiter(fn) {
      let token = waiter.beginAsync();
      let result;
      try {
        result = await fn();
      } catch (err) {
        throw err;
      } finally {
        waiter.endAsync(token);
      }
      return result;
    }
    async action(actionName) {
      if (actionName == 'deleteAll') {
        return this.deleteAll();
      }
      return this.doWithWaiter(async () => {
        if (this.allSelected) {
          await this.selectedValues[0][`${actionName}Many`](this.args.query);
          return this.args.reload?.();
        }
        let promises = [];
        for (const value of this.selectedValues) {
          promises.push(value[actionName]());
        }
        await Promise.all(promises);
        return this.args.reload?.();
      });
    }
    async deleteAll() {
      try {
        await this.modal.confirm(this.intl.t(`delete all ${this.modelName}s`), this.intl.t(`delete-all-confirmation-message-${this.modelName}`));
      } catch (err) {
        return false;
      }
      return this.doWithWaiter(async () => {
        await this.firstRecord.deleteMany(this.args.query);
        await this.args.reload?.();
      });
    }
    async delete() {
      try {
        const name = this.allValues?.find(a => a.id == this.selection[0]).name ?? '';
        await this.modal.confirm(this.intl.t(`delete ${this.modelName}`, {
          size: this.selection.length
        }), this.intl.t('delete-confirmation-message', {
          size: this.selection.length,
          name
        }));
      } catch (err) {
        return false;
      }
      return this.doWithWaiter(async () => {
        const promises = this.selection.map(id => this.allValues.find(a => a.id == id)).map(a => a.destroyRecord());
        await Promise.all(promises);
        await this.args.reload?.();
      });
    }
    onSelect(value) {
      this.selection = value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "action", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "action"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSelectableListComponent);
});