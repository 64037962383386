define("map/components/map/interaction/dbl-click-drag-zoom", ["exports", "@ember/component", "@glimmer/component", "ol/interaction/DblClickDragZoom", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _DblClickDragZoom, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='d-none ol-interaction-dbl-click-drag-zoom'
    {{did-insert this.setupOl}}
    {{did-update this.setupOl @map @duration @delta}}
  ></div>
  */
  {
    "id": "uqSn/kyy",
    "block": "[[[11,0],[24,0,\"d-none ol-interaction-dbl-click-drag-zoom\"],[4,[38,1],[[30,0,[\"setupOl\"]]],null],[4,[38,2],[[30,0,[\"setupOl\"]],[30,1],[30,2],[30,3]],null],[12],[13]],[\"@map\",\"@duration\",\"@delta\"],false,[\"div\",\"did-insert\",\"did-update\"]]",
    "moduleName": "map/components/map/interaction/dbl-click-drag-zoom.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let MapInteractionDblClickDragZoomComponent = _exports.default = (_class = class MapInteractionDblClickDragZoomComponent extends _component2.default {
    setupOl() {
      if (!this.args.map) {
        return;
      }
      this.interaction = new _DblClickDragZoom.default({
        duration: this.args.duration ?? 0,
        delta: this.args.delta ?? 20,
        stopDown: this.args.stopDown
      });
      this.args.map.addInteraction(this.interaction);
      this.currentInteractions = [this.interaction];
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.currentInteractions && this.args.map) {
        this.currentInteractions.forEach(item => {
          this.args.map.removeInteraction(item);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setupOl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupOl"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapInteractionDblClickDragZoomComponent);
});