define("manage/components/manage/survey-notifications", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    ...attributes
  >
    <:view>
      {{#if @value.add}}
        <p>
          {{t 'message-alert-survey-add-notification'}}
          <LinkTo @route='manage.articles.edit' @model={{@value.add}}>{{t
              'edit notification'
            }}</LinkTo>.
        </p>
      {{else}}
        <div class='alert alert-primary' role='alert'>
          {{t 'message-alert-survey-no-notifications'}}
        </div>
      {{/if}}
    </:view>
  
    <:edit>
      <div class='form-check form-switch'>
        <Input
          @type='checkbox'
          @checked={{this.checked}}
          class='form-check-input btn-switch'
          disabled={{or this.isSaving @disabled}}
          id='{{this.elementId}}-checkbox'
        />
  
        <label class='form-check-label' for='{{this.elementId}}-checkbox'>
          {{t 'enable all notifications'}}
        </label>
      </div>
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "rI/erivh",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,0,[\"isDisabled\"]],[30,3],[30,4],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,5,[\"add\"]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"message-alert-survey-add-notification\"],null]],[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"manage.articles.edit\",[30,5,[\"add\"]]]],[[\"default\"],[[[[1,[28,[35,4],[\"edit notification\"],null]]],[]]]]],[1,\".\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-primary\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"message-alert-survey-no-notifications\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"form-check form-switch\"],[12],[1,\"\\n      \"],[8,[39,8],[[24,0,\"form-check-input btn-switch\"],[16,\"disabled\",[28,[37,9],[[30,0,[\"isSaving\"]],[30,6]],null]],[16,1,[29,[[30,0,[\"elementId\"]],\"-checkbox\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null],[1,\"\\n\\n      \"],[10,\"label\"],[14,0,\"form-check-label\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"-checkbox\"]]],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"enable all notifications\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@editablePanel\",\"@onEdit\",\"@value\",\"@disabled\"],false,[\"manage/field-container\",\":view\",\"if\",\"p\",\"t\",\"link-to\",\"div\",\":edit\",\"input\",\"or\",\"label\"]]",
    "moduleName": "manage/components/manage/survey-notifications.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageSurveyNotificationsComponent = _exports.default = (_class = class ManageSurveyNotificationsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_checked", _descriptor, this);
    }
    get checked() {
      if (this._checked !== null) {
        return this._checked;
      }
      return typeof this.args.value?.add == 'string';
    }
    set checked(value) {
      this._checked = value;
    }
    get notChanged() {
      const hasNotification = typeof this.args.value?.add == 'string';
      return this.checked == hasNotification;
    }
    save() {
      if (this.notChanged) {
        return this.cancel();
      }
      this.args.onSave?.(this.checked);
    }
    cancel() {
      this._checked = null;
      this.args.onCancel?.();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_checked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSurveyNotificationsComponent);
});