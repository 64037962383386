define("spaces/components/editor/container-options", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::ContainerOptions
    class="container-options-sheet container-options-sheet-components-editor"
    @container={{@value}}
    @onChange={{@onChange}}
  />
  
  */
  {
    "id": "ctvcPXVc",
    "block": "[[[8,[39,0],[[24,0,\"container-options-sheet container-options-sheet-components-editor\"]],[[\"@container\",\"@onChange\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[\"@value\",\"@onChange\"],false,[\"input/options/container-options\"]]",
    "moduleName": "spaces/components/editor/container-options.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorContainerOptionsComponent extends _component2.default {}
  _exports.default = EditorContainerOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorContainerOptionsComponent);
});