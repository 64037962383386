define("map/components/point-icon", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="point-icon text-danger">
    <FaIcon @icon="map-pin" @size="4x" />
  </div>
  */
  {
    "id": "BPEIcMld",
    "block": "[[[10,0],[14,0,\"point-icon text-danger\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@icon\",\"@size\"],[\"map-pin\",\"4x\"]],null],[1,\"\\n\"],[13]],[],false,[\"div\",\"fa-icon\"]]",
    "moduleName": "map/components/point-icon.hbs",
    "isStrictMode": false
  });
  class PointIconComponent extends _component2.default {}
  _exports.default = PointIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PointIconComponent);
});