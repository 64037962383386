define("manage/components/manage/article", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @isSaveDisabled={{this.isDisabled}} @editablePanel={{@editablePanel}} @onEdit={{@onEdit}} @onSave={{this.save}} @onCancel={{this.cancel}} ...attributes>
    <:view>
      <Article::Viewer @value={{this.value}} as |value|>
        {{#unless (eq value.type "map-view")}}
          <View::View
            @value={{value}}
            @model={{this.model}}
            @space={{@space}}
            @state={{@state}}
            @isEditor={{true}}
          />
        {{/unless}}
      </Article::Viewer>
    </:view>
  
    <:edit>
      <Article::Editor class="article-with-borders mb-3" @allowVideo={{@allowVideo}} @value={{this.value}} @onSave={{this.save}} @onChange={{this.change}} @onPictureUpload={{@onPictureUpload}} />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "jT8g5afO",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\"],[[30,0,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[28,[37,4],[[30,6,[\"type\"]],\"map-view\"],null]],[[[1,\"        \"],[8,[39,5],null,[[\"@value\",\"@model\",\"@space\",\"@state\",\"@isEditor\"],[[30,6],[30,0,[\"model\"]],[30,7],[30,8],true]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[6]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"article-with-borders mb-3\"]],[[\"@allowVideo\",\"@value\",\"@onSave\",\"@onChange\",\"@onPictureUpload\"],[[30,9],[30,0,[\"value\"]],[30,0,[\"save\"]],[30,0,[\"change\"]],[30,10]]],null],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"value\",\"@space\",\"@state\",\"@allowVideo\",\"@onPictureUpload\"],false,[\"manage/field-container\",\":view\",\"article/viewer\",\"unless\",\"eq\",\"view/view\",\":edit\",\"article/editor\"]]",
    "moduleName": "manage/components/manage/article.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageArticleComponent = _exports.default = (_class = class ManageArticleComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
      _initializerDefineProperty(this, "_title", _descriptor2, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value;
    }
    get isDisabled() {
      return this._value ? false : true;
    }
    change(title, article) {
      this._title = title;
      this._value = article;
    }
    cancel() {
      this._value = null;
      return this.args.onCancel?.();
    }
    save() {
      return this.args.onSave?.(this._title, this._value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageArticleComponent);
});