define("spaces/components/editor/source-attribute", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::Properties::OptionContainer @label={{t "option-panel.attribute-path"}} as |id|>
    <Input::Text
      class="form-control input-attribute-field"
      id={{id}}
      @value={{this.attributeField}}
      @onChange={{fn this.change "attributeField"}}
    />
  </Editor::Properties::OptionContainer>
  
  */
  {
    "id": "wlTI8mK0",
    "block": "[[[8,[39,0],null,[[\"@label\"],[[28,[37,1],[\"option-panel.attribute-path\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"form-control input-attribute-field\"],[16,1,[30,1]]],[[\"@value\",\"@onChange\"],[[30,0,[\"attributeField\"]],[28,[37,3],[[30,0,[\"change\"]],\"attributeField\"],null]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"id\"],false,[\"editor/properties/option-container\",\"t\",\"input/text\",\"fn\"]]",
    "moduleName": "spaces/components/editor/source-attribute.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let EditorSourceAttributeComponent = _exports.default = (_class = class EditorSourceAttributeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_attributeField", _descriptor, this);
    }
    get attributeField() {
      if (this._attributeField) {
        return this._attributeField;
      }
      return this.args.value?.field ?? "";
    }
    triggerChange() {
      return this.args.onChange?.({
        field: this.attributeField
      });
    }
    change(key, value) {
      this[`_${key}`] = value;
      this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_attributeField", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorSourceAttributeComponent);
});