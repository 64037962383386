define("map/components/attributes/group-map-base", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "core/lib/geoJson", "core/lib/positionDetails", "@ember/runloop"], function (_exports, _component, _service, _tracking, _object, _geoJson, _positionDetails, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AttributesGroupMapBaseComponent = _exports.default = (_class = class AttributesGroupMapBaseComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "fullscreen", _descriptor2, this);
      _initializerDefineProperty(this, "mapStyles", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "editMode", _descriptor5, this);
      _initializerDefineProperty(this, "_selectedBaseMap", _descriptor6, this);
      _initializerDefineProperty(this, "ignoreWebGl", _descriptor7, this);
      _initializerDefineProperty(this, "map", _descriptor8, this);
      _initializerDefineProperty(this, "baseMaps", _descriptor9, this);
      _defineProperty(this, "dismissedAt", 0);
    }
    get markerMessage() {
      if (this.details.type != 'manual') {
        return null;
      }
      return 'message-map-marker-move';
    }
    get extentLabelPosition() {
      const extent = this.args.extent.toExtent();
      const center = this.args.extent.center.coordinates;
      center[1] = extent[3];
      return center;
    }
    get extentStyle() {
      return this.mapStyles.extent(this.mapStyleElement);
    }
    layerSetupError(layer) {
      if (layer.type == 'MapBox') {
        this.ignoreWebGl = true;
      }
    }
    get baseMaps() {
      if (this.ignoreWebGl) {
        return this.args.map?.baseMaps?.filter(a => !a.needsWebGl) ?? [];
      }
      return this.args.map?.baseMaps ?? [];
    }
    get selectedBaseMap() {
      if (this._selectedBaseMap) {
        return this._selectedBaseMap;
      }
      return this.baseMaps?.[0] ?? null;
    }
    get mapExtent() {
      if (this.hasPositionInExtent || this.hasUpdatedPos) {
        const coordinates = this.args.position?.coordinates ?? [0, 0];
        const radius = 0.005;
        const topRight = [coordinates[0] - radius, coordinates[1] - radius];
        const bottomLeft = [coordinates[0] + radius, coordinates[1] + radius];
        return topRight.concat(bottomLeft);
      }
      if (this.args.extent) {
        const geoJson = new _geoJson.GeoJson(this.args.extent);
        const extent = geoJson.toOlFeature().getGeometry().getExtent();
        return extent;
      }
      return [-114, -41, 42, 72];
    }
    get details() {
      return this.args.details ?? {};
    }
    get position() {
      return new _geoJson.GeoJson(this.args.position ?? {});
    }
    get bearer() {
      let {
        access_token
      } = this.session?.get?.('data.authenticated') ?? null;
      if (!access_token) {
        return null;
      }
      return `Bearer ${access_token}`;
    }
    mapSetup(map) {
      this._map = map;
      this._mapView = map.getView();
    }
    edit() {
      this.editMode = true;
      this.fullscreen.freezeBody();
      this.prevPosition = new _geoJson.GeoJson(this.position);
      this.prevDetails = new _positionDetails.default(this.details);
      this.selectedPosition = null;
      this.selectedDetails = null;
    }
    selectBaseMap(value) {
      this._selectedBaseMap = value;
    }
    confirm() {
      this.editMode = false;
      this.fullscreen.unfreezeBody();
      this.hasUpdatedPos = true;
      this.updateMapSize();
    }
    dismiss() {
      this.dismissedAt = new Date().getTime();
      this.editMode = false;
      this.fullscreen.unfreezeBody();
      this.args.onChange?.(this.prevPosition, this.prevDetails);
      (0, _runloop.later)(() => {
        this.updateMapSize();
      }, 100);
    }
    updateMapSize() {
      this._map?.updateSize?.();
    }
    updatePosition(map, view) {
      this._map = map;
      this._mapView = view;
    }
    async setup() {
      this.oldType = this.details.type;
      if (this.args.campaign) {
        this.map = await this.args.campaign.map.fetch();
        this.baseMaps = await this.args.campaign.baseMaps.fetch();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mapStyles", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "editMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_selectedBaseMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ignoreWebGl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "map", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "baseMaps", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "layerSetupError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "layerSetupError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mapSetup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mapSetup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectBaseMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectBaseMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMapSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateMapSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePosition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
});