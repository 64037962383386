define("manage/components/manage/card-feature", ["exports", "@ember/component", "manage/components/manage/card", "@ember/object", "@ember/template", "@glimmer/tracking", "@ember/service", "@ember/template-factory"], function (_exports, _component, _card, _object, _template, _tracking, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-card-layout manage-card-feature" {{did-insert this.setup}}>
    <div class="cover mb-3 {{unless this.hasCover "no-cover"}}" style={{this.bgCover}}>
      {{#if @onSelect}}
        <ChkRecordSelection @record={{this.record}} @selection={{@selection}} @onSelect={{@onSelect}} />
      {{/if}}
    </div>
    <div class="mb-2">
      <span class="badge text-bg-{{this.visibilityClass}} float-end visibility">{{t this.visibilityText}}</span>
  
      <span class="name">{{@record.title}}</span>
    </div>
    <div class="description mb-3 {{unless @record.firstParagraph 'text-danger'}}">{{this.firstParagraph}}</div>
  
    {{#if @record.canEdit}}
      <div class="action-container btn-group w-100">
        <LinkTo class="btn btn-main-action" @route="manage.features.edit" @model={{@record.id}}>
          {{t "edit"}}
        </LinkTo>
  
        <Dropdown @isDropdownToggle={{true}} @toggleClass="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split btn-open-options">
          <ModelActions::Record @record={{@record}} @onDelete={{@onDelete}} />
        </Dropdown>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "QU0KzhDy",
    "block": "[[[11,0],[24,0,\"manage-card-layout manage-card-feature\"],[4,[38,1],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"cover mb-3 \",[52,[51,[30,0,[\"hasCover\"]]],\"no-cover\"]]]],[15,5,[30,0,[\"bgCover\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[39,4],null,[[\"@record\",\"@selection\",\"@onSelect\"],[[30,0,[\"record\"]],[30,2],[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mb-2\"],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"badge text-bg-\",[30,0,[\"visibilityClass\"]],\" float-end visibility\"]]],[12],[1,[28,[35,6],[[30,0,[\"visibilityText\"]]],null]],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"name\"],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"description mb-3 \",[52,[51,[30,3,[\"firstParagraph\"]]],\"text-danger\"]]]],[12],[1,[30,0,[\"firstParagraph\"]]],[13],[1,\"\\n\\n\"],[41,[30,3,[\"canEdit\"]],[[[1,\"    \"],[10,0],[14,0,\"action-container btn-group w-100\"],[12],[1,\"\\n      \"],[8,[39,7],[[24,0,\"btn btn-main-action\"]],[[\"@route\",\"@model\"],[\"manage.features.edit\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[\"edit\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,8],null,[[\"@isDropdownToggle\",\"@toggleClass\"],[true,\"btn btn-outline-secondary dropdown-toggle dropdown-toggle-split btn-open-options\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,9],null,[[\"@record\",\"@onDelete\"],[[30,3],[30,4]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@onSelect\",\"@selection\",\"@record\",\"@onDelete\"],false,[\"div\",\"did-insert\",\"unless\",\"if\",\"chk-record-selection\",\"span\",\"t\",\"link-to\",\"dropdown\",\"model-actions/record\"]]",
    "moduleName": "manage/components/manage/card-feature.hbs",
    "isStrictMode": false
  });
  let ManageFeatureCard = _exports.default = (_class = class ManageFeatureCard extends _card.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "cover", _descriptor2, this);
    }
    get firstParagraph() {
      if (!this.args?.record?.firstParagraph) {
        return this.intl.t('message-no-feature-description');
      }
      return this.args?.record?.firstParagraph;
    }
    get visibilityClass() {
      if (this.args.record?.visibility == 1) {
        return 'success';
      }
      if (this.args.record?.visibility == -1) {
        return 'info';
      }
      return 'warning';
    }
    get hasCover() {
      return this.cover?.picture;
    }
    get bgCover() {
      if (!this.cover) {
        return (0, _template.htmlSafe)('');
      }
      return (0, _template.htmlSafe)(`background-image: url('${this.cover.picture}/md')`);
    }
    get visibilityText() {
      if (this.args.record?.visibility == 1) {
        return 'visibility-public';
      }
      if (this.args.record?.visibility == -1) {
        return 'visibility-pending';
      }
      return 'visibility-private';
    }
    async setup() {
      this.cover = await this.args.record?.getCover?.();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cover", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageFeatureCard);
});