define("manage/components/manage/contributor-questions", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/array", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _array, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{@isDisabled}}
    @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    ...attributes
  >
    <:view>
      <QuestionsView @value={{this.viewQuestions}} />
  
      {{#unless this.viewQuestions}}
        <div class="alert alert-warning">
          {{t "question-message.no-user-questions"}}
        </div>
      {{/unless}}
    </:view>
  
    <:edit>
      <Input::Questions @value={{this.questions}} @onChange={{this.changeValue}} />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "V86ZB8oI",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\"],[[30,0,[\"viewQuestions\"]]]],null],[1,\"\\n\\n\"],[41,[51,[30,0,[\"viewQuestions\"]]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"question-message.no-user-questions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],null,[[\"@value\",\"@onChange\"],[[30,0,[\"questions\"]],[30,0,[\"changeValue\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\"],false,[\"manage/field-container\",\":view\",\"questions-view\",\"unless\",\"div\",\"t\",\":edit\",\"input/questions\"]]",
    "moduleName": "manage/components/manage/contributor-questions.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageContributorQuestionsComponent = _exports.default = (_class = class ManageContributorQuestionsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value ?? [];
    }
    get emailQuestion() {
      const item = this.args.value?.find(a => a.name == 'email') ?? {};
      const exists = !!this.args.value?.find(a => a.name == 'email');
      return {
        question: item.question ?? 'What is your email?',
        help: item.help ?? 'We need your email to let you know if we have questions about your contribution and to let you know about the progress.',
        type: 'email',
        name: 'email',
        isRequired: exists ? item.isVisible : true,
        isVisible: exists ? item.isVisible : false,
        options: item.options,
        disableDelete: true,
        hasVisibility: true
      };
    }
    mapQuestion(question) {
      if (question.name == 'email') {
        return this.emailQuestion;
      }
      return question;
    }
    get viewQuestions() {
      return this.questions.filter(a => a.isVisible);
    }
    get questions() {
      const result = (0, _array.A)(this.value.map(a => this.mapQuestion(a)));
      if (!result.find(a => a.name === 'email')) {
        result.insertAt(0, this.emailQuestion);
      }
      return result;
    }
    save() {
      return this.args?.onSave(this.value);
    }
    cancel() {
      this._value = undefined;
      return this.args?.onCancel();
    }
    changeValue(value) {
      this._value = value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageContributorQuestionsComponent);
});