define("spaces/components/page-editor", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Selection = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::PageEditors @onDeselect={{this.selectPage}} @title={{this.selection.title}}>
    <:panel>
      <Manage::PageFrame class={{this.deviceSizeClass}}>
        <:header>
          <PageFrameHeader
            @model={{@model}}
            @deviceSize={{this.deviceSize}}
            @onChangeDeviceSize={{this.changeDeviceSize}}
            @conditions={{this.conditions}}
            @onChangeConditions={{this.changeConditions}}
            @onSave={{this.save}}
            @onClone={{@onClone}}
          />
        </:header>
  
        <:body>
          {{#unless @model.page.layoutContainers}}
            <div class="p-3 text-center empty-layout">
              <p>{{t "message-empty-layout"}}</p>
              <LinkTo
                class="btn btn-sm btn-outline-primary ms-2 btn-show-layout"
                @route="manage.pages.layout"
                @model={{@model.page.id}}
              >
                {{capitalize (t "edit layout")}}
              </LinkTo>
            </div>
          {{/unless}}
  
          <PageFrameEdit
            @conditions={{this.conditions}}
            @selection={{this.selection}}
            @onSelect={{this.select}}
            @model={{@model}}
            @deviceSize={{this.deviceSize}}
            @state={{@state}}
            @onChangeState={{@onChangeState}}
          />
        </:body>
      </Manage::PageFrame>
    </:panel>
  
    <:content>
      <Manage::PropertyPanel
        @deviceSize={{this.deviceSize}}
        @selection={{this.selection}}
        @model={{@model}}
        @onRevertPage={{this.revertPage}}
        @onSpaceChange={{this.spaceChange}}
      />
    </:content>
  </Editor::PageEditors>
  */
  {
    "id": "p8Cz3xVg",
    "block": "[[[8,[39,0],null,[[\"@onDeselect\",\"@title\"],[[30,0,[\"selectPage\"]],[30,0,[\"selection\",\"title\"]]]],[[\"panel\",\"content\"],[[[[1,\"\\n    \"],[8,[39,2],[[16,0,[30,0,[\"deviceSizeClass\"]]]],null,[[\"header\",\"body\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@model\",\"@deviceSize\",\"@onChangeDeviceSize\",\"@conditions\",\"@onChangeConditions\",\"@onSave\",\"@onClone\"],[[30,1],[30,0,[\"deviceSize\"]],[30,0,[\"changeDeviceSize\"]],[30,0,[\"conditions\"]],[30,0,[\"changeConditions\"]],[30,0,[\"save\"]],[30,2]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n\"],[41,[51,[30,1,[\"page\",\"layoutContainers\"]]],[[[1,\"          \"],[10,0],[14,0,\"p-3 text-center empty-layout\"],[12],[1,\"\\n            \"],[10,2],[12],[1,[28,[35,9],[\"message-empty-layout\"],null]],[13],[1,\"\\n            \"],[8,[39,10],[[24,0,\"btn btn-sm btn-outline-primary ms-2 btn-show-layout\"]],[[\"@route\",\"@model\"],[\"manage.pages.layout\",[30,1,[\"page\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[35,11],[[28,[37,9],[\"edit layout\"],null]],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,12],null,[[\"@conditions\",\"@selection\",\"@onSelect\",\"@model\",\"@deviceSize\",\"@state\",\"@onChangeState\"],[[30,0,[\"conditions\"]],[30,0,[\"selection\"]],[30,0,[\"select\"]],[30,1],[30,0,[\"deviceSize\"]],[30,3],[30,4]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,14],null,[[\"@deviceSize\",\"@selection\",\"@model\",\"@onRevertPage\",\"@onSpaceChange\"],[[30,0,[\"deviceSize\"]],[30,0,[\"selection\"]],[30,1],[30,0,[\"revertPage\"]],[30,0,[\"spaceChange\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@onClone\",\"@state\",\"@onChangeState\"],false,[\"editor/page-editors\",\":panel\",\"manage/page-frame\",\":header\",\"page-frame-header\",\":body\",\"unless\",\"div\",\"p\",\"t\",\"link-to\",\"capitalize\",\"page-frame-edit\",\":content\",\"manage/property-panel\"]]",
    "moduleName": "spaces/components/page-editor.hbs",
    "isStrictMode": false
  });
  let Selection = _exports.Selection = (_class = class Selection {
    constructor(type, title, data) {
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "title", _descriptor2, this);
      _initializerDefineProperty(this, "data", _descriptor3, this);
      if (typeof type == 'object') {
        this.type = type.type;
        this.title = type.title;
        this.data = type.data ?? {};
        return;
      }
      this.type = type;
      this.title = title;
      this.data = data ?? {};
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let PageEditor = _exports.default = (_class2 = class PageEditor extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "selection", _descriptor4, this);
      _initializerDefineProperty(this, "deviceSize", _descriptor5, this);
      _initializerDefineProperty(this, "conditions", _descriptor6, this);
      _initializerDefineProperty(this, "_hasSpaceUpdate", _descriptor7, this);
      _initializerDefineProperty(this, "hasRestoredPage", _descriptor8, this);
    }
    get hasSpaceUpdate() {
      return this._hasSpaceUpdate || this.args.model.space.hasDirtyAttributes;
    }
    selectPage() {
      this.selection = new Selection('page', 'page');
    }
    get deviceSizeClass() {
      if (!this.deviceSize) {
        return '';
      }
      return `enforce-${this.deviceSize}-size`;
    }
    changeDeviceSize(value) {
      this.deviceSize = value;
    }
    changeConditions(newConditions) {
      this.conditions = newConditions;
    }
    spaceChange(value) {
      this.args.model.space = value;
      this.args.model.page.space = value;
      this._hasSpaceUpdate = true;
    }
    select(selection) {
      if (selection?.isHighlight) {
        this.selection = selection;
        return;
      }
      if (this.selection && this.selection.title == selection?.title) {
        return;
      }
      if (selection?.type == 'col') {
        selection.data = this.args.model.page.upsertPageColByName(selection.data.name);
      }
      this.selection = new Selection(selection);
      if (selection?.type == 'col') {
        this.selection.data.store = this.store;
      }
    }
    async revertPage(revisionId) {
      this.args.model.page = await this.args.model.page.loadRevision(revisionId);
      this.hasRestoredPage = true;
    }
    async save() {
      if (this.hasSpaceUpdate) {
        await this.args.model.space.save();
        this._hasSpaceUpdate = false;
      }
      for (const key in this.args.model) {
        const resolvedRecord = await this.args.model[key];
        if (resolvedRecord?.shouldSave && key != "page") {
          await resolvedRecord.save();
        }
      }
      this.args.model.page.space = this.args.model.space;
      const promise = this.args.model.page.save();
      this.hasRestoredPage = false;
      return promise;
    }
  }, (_descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Selection('page', 'page');
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "deviceSize", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "conditions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['user:unauthenticated'];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "_hasSpaceUpdate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "hasRestoredPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "selectPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeDeviceSize", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeDeviceSize"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeConditions", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeConditions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "spaceChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "spaceChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "revertPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "revertPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageEditor);
});