define("spaces/components/editor/source-buckets", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::ModelItems @space={{@space}} @value={{@value}} @model={{@model}} @onChange={{@onChange}} @preferredModel={{this.preferredModel}}/>
  
  */
  {
    "id": "xaqfsism",
    "block": "[[[8,[39,0],null,[[\"@space\",\"@value\",\"@model\",\"@onChange\",\"@preferredModel\"],[[30,1],[30,2],[30,3],[30,4],[30,0,[\"preferredModel\"]]]],null],[1,\"\\n\"]],[\"@space\",\"@value\",\"@model\",\"@onChange\"],false,[\"input/options/model-items\"]]",
    "moduleName": "spaces/components/editor/source-buckets.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorSourceBucketsComponent extends _component2.default {
    get preferredModel() {
      return this.args.element?.attributes?.getNamedItem?.("data-preferred-model")?.value;
    }
  }
  _exports.default = EditorSourceBucketsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorSourceBucketsComponent);
});