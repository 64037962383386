define("manage/components/input/event-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::ListWithAdd class="input-event-list" @disableAdd={{not @list.length}} @plusLabel={{t "event"}} @value={{@value}} @factoryValue={{this.factoryValue}} @onChange={{@onChange}} as |value change|>
    <Input::EventSelector @team={{@team}} @value={{value}} @onChange={{change}} />
  </Input::ListWithAdd>
  
  */
  {
    "id": "0aB1LZn4",
    "block": "[[[8,[39,0],[[24,0,\"input-event-list\"]],[[\"@disableAdd\",\"@plusLabel\",\"@value\",\"@factoryValue\",\"@onChange\"],[[28,[37,1],[[30,1,[\"length\"]]],null],[28,[37,2],[\"event\"],null],[30,2],[30,0,[\"factoryValue\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@team\",\"@value\",\"@onChange\"],[[30,6],[30,4],[30,5]]],null],[1,\"\\n\"]],[4,5]]]]],[1,\"\\n\"]],[\"@list\",\"@value\",\"@onChange\",\"value\",\"change\",\"@team\"],false,[\"input/list-with-add\",\"not\",\"t\",\"input/event-selector\"]]",
    "moduleName": "manage/components/input/event-list.hbs",
    "isStrictMode": false
  });
  let InputEventList = _exports.default = (_class = class InputEventList extends _component2.default {
    async factoryValue() {
      const list = typeof this.args.list?.toArray ? (await this.args.list).toArray() : this.args.list;
      return list[0] ?? null;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "factoryValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "factoryValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputEventList);
});