define("spaces-view/components/view/generic-list", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.isEmpty @isEditor)}}
    <div data-editor="generic-list" data-type="list" class="generic-list empty" ...attributes>
      {{t "message-generic-list-empty"}}
    </div>
  {{else}}
    {{#each @value as |record index|}}
      {{yield record index}}
    {{/each}}
  
    <div data-editor="generic-list" data-type="list" class="generic-list d-none" ...attributes>
    </div>
  {{/if}}
  */
  {
    "id": "uo01wnRX",
    "block": "[[[41,[28,[37,1],[[30,0,[\"isEmpty\"]],[30,1]],null],[[[1,\"  \"],[11,0],[24,\"data-editor\",\"generic-list\"],[24,\"data-type\",\"list\"],[24,0,\"generic-list empty\"],[17,2],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"message-generic-list-empty\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,5],[[28,[37,5],[[30,3]],null]],null],null,[[[1,\"    \"],[18,6,[[30,4],[30,5]]],[1,\"\\n\"]],[4,5]],null],[1,\"\\n  \"],[11,0],[24,\"data-editor\",\"generic-list\"],[24,\"data-type\",\"list\"],[24,0,\"generic-list d-none\"],[17,2],[12],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@isEditor\",\"&attrs\",\"@value\",\"record\",\"index\",\"&default\"],false,[\"if\",\"and\",\"div\",\"t\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "spaces-view/components/view/generic-list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class ViewGenericListComponent extends _component2.default {
    get isEmpty() {
      return !this.args.value?.length;
    }
  }
  _exports.default = ViewGenericListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewGenericListComponent);
});