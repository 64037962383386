define("manage/routes/manage/newsletters/import", ["exports", "manage/routes/base/authenticated-route", "rsvp"], function (_exports, _authenticatedRoute, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class ManageNewslettersImportRoute extends _authenticatedRoute.default {
    async model(params) {
      if (this.isFastBoot) {
        return null;
      }
      const newsletter = await this.store.findRecord('newsletter', params.id);
      return (0, _rsvp.hash)({
        newsletter,
        team: newsletter.visibility.fetchTeam()
      });
    }
  }
  _exports.default = ManageNewslettersImportRoute;
});