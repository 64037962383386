define("spaces-view/components/view/fragment", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord
    @path={{@path}}
    @model={{@model}}
    @value={{@value}}
    @isEditor={{@isEditor}}
    @onLoad={{this.setup}}
    data-preferred-model="page"
    class="view-fragment use-highlights"
    as | page |
  >
    <Layout
      @page={{page}}
      @model={{@model}}
      @size={{@deviceSize}}
      @path={{@value.modelKey}}
      as |layoutCol|
    >
      <View::Selector
        @cols={{page.cols}}
        @ignoredTypes={{this.ignoredTypes}}
        @layoutCol={{layoutCol}}
        as |col index name|
      >
        <View::View
          @model={{@model}}
          @deviceSize={{@deviceSize}}
          @space={{@space}}
          @value={{col}}
          @isEditor={{@isEditor}}
          @state={{@state}}
          @path={{concat @value.modelKey ".cols." index}}
          @onChangeState={{@onChangeState}}
          {{did-insert (fn this.ensureColExists name page)}}
        />
      </View::Selector>
    </Layout>
  </View::Base::SourceRecord>
  */
  {
    "id": "VMOzNqV3",
    "block": "[[[8,[39,0],[[24,\"data-preferred-model\",\"page\"],[24,0,\"view-fragment use-highlights\"]],[[\"@path\",\"@model\",\"@value\",\"@isEditor\",\"@onLoad\"],[[30,1],[30,2],[30,3],[30,4],[30,0,[\"setup\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@page\",\"@model\",\"@size\",\"@path\"],[[30,5],[30,2],[30,6],[30,3,[\"modelKey\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@cols\",\"@ignoredTypes\",\"@layoutCol\"],[[30,5,[\"cols\"]],[30,0,[\"ignoredTypes\"]],[30,7]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],[[4,[38,5],[[28,[37,6],[[30,0,[\"ensureColExists\"]],[30,10],[30,5]],null]],null]],[[\"@model\",\"@deviceSize\",\"@space\",\"@value\",\"@isEditor\",\"@state\",\"@path\",\"@onChangeState\"],[[30,2],[30,6],[30,11],[30,8],[30,4],[30,12],[28,[37,4],[[30,3,[\"modelKey\"]],\".cols.\",[30,9]],null],[30,13]]],null],[1,\"\\n    \"]],[8,9,10]]]]],[1,\"\\n  \"]],[7]]]]],[1,\"\\n\"]],[5]]]]]],[\"@path\",\"@model\",\"@value\",\"@isEditor\",\"page\",\"@deviceSize\",\"layoutCol\",\"col\",\"index\",\"name\",\"@space\",\"@state\",\"@onChangeState\"],false,[\"view/base/source-record\",\"layout\",\"view/selector\",\"view/view\",\"concat\",\"did-insert\",\"fn\"]]",
    "moduleName": "spaces-view/components/view/fragment.hbs",
    "isStrictMode": false
  });
  let ViewFragmentComponent = _exports.default = (_class = class ViewFragmentComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "page", _descriptor, this);
      _defineProperty(this, "ignoredTypes", ['fragment']);
    }
    setup(value) {
      this.page = value;
    }
    ensureColExists(name, page) {
      (0, _runloop.later)(() => {
        page.upsertPageColByName(name);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ensureColExists", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "ensureColExists"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewFragmentComponent);
});