define("spaces-view/components/view/embedded-video", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Container
    @value={{@value.data.container}}
    @deviceSize={{@deviceSize}}
    class="page-col-embedded-video use-highlights {{unless @value.data.source.url 'empty'}}"
    data-name={{@value.name}}
    data-gid={{@value.gid}}
    data-path="{{@path}}.data.container"
    ...attributes
  >
    {{! template-lint-disable require-media-caption }}
    <video
      loop
      autoplay
      data-name={{@value.name}}
      data-gid={{@value.gid}}
      data-editor="video-source"
      data-type="video"
      data-path="{{@path}}.data.source"
    >
      {{#if @value.data.source.url}}
        <source src={{@value.data.source.url}} type="video/mp4" />
      {{/if}}
    </video>
  </Container>
  */
  {
    "id": "UGqm+HYf",
    "block": "[[[8,[39,0],[[16,0,[29,[\"page-col-embedded-video use-highlights \",[52,[51,[30,1,[\"data\",\"source\",\"url\"]]],\"empty\"]]]],[16,\"data-name\",[30,1,[\"name\"]]],[16,\"data-gid\",[30,1,[\"gid\"]]],[16,\"data-path\",[29,[[30,2],\".data.container\"]]],[17,3]],[[\"@value\",\"@deviceSize\"],[[30,1,[\"data\",\"container\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"  \"],[10,\"video\"],[14,\"loop\",\"\"],[14,\"autoplay\",\"\"],[15,\"data-name\",[30,1,[\"name\"]]],[15,\"data-gid\",[30,1,[\"gid\"]]],[14,\"data-editor\",\"video-source\"],[14,\"data-type\",\"video\"],[15,\"data-path\",[29,[[30,2],\".data.source\"]]],[12],[1,\"\\n\"],[41,[30,1,[\"data\",\"source\",\"url\"]],[[[1,\"      \"],[10,\"source\"],[15,\"src\",[30,1,[\"data\",\"source\",\"url\"]]],[14,4,\"video/mp4\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@value\",\"@path\",\"&attrs\",\"@deviceSize\"],false,[\"container\",\"unless\",\"video\",\"if\",\"source\"]]",
    "moduleName": "spaces-view/components/view/embedded-video.hbs",
    "isStrictMode": false
  });
  class ViewEmbeddedVideo extends _component2.default {}
  _exports.default = ViewEmbeddedVideo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewEmbeddedVideo);
});