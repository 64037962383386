define("spaces-view/components/page-not-found", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title (t "page-not-found.title")}}
  
  <div class="error page-not-found d-flex h-100">
    <div class="container justify-content-center align-self-center text-center">
      <p class="text-info">
        <FaIcon @icon="pizza-slice" @size="3x" />
      </p>
  
      <h1>
        {{t "page-not-found.title"}}
      </h1>
  
      <p class="text-info">
        {{t "page-not-found.message"}}
      </p>
  
      <p class="text-muted">
        {{t "page-not-found.contact-admin"}}
      </p>
    </div>
  </div>
  */
  {
    "id": "91xZnstK",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"page-not-found.title\"],null]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"error page-not-found d-flex h-100\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container justify-content-center align-self-center text-center\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"text-info\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@icon\",\"@size\"],[\"pizza-slice\",\"3x\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"page-not-found.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[14,0,\"text-info\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"page-not-found.message\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[14,0,\"text-muted\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"page-not-found.contact-admin\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"div\",\"p\",\"fa-icon\",\"h1\"]]",
    "moduleName": "spaces-view/components/page-not-found.hbs",
    "isStrictMode": false
  });
  class PageNotFoundComponent extends _component2.default {}
  _exports.default = PageNotFoundComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageNotFoundComponent);
});