define("spaces/components/editor/attribute", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::ModelItem
    @value={{this.source}}
    @onChange={{fn this.change "source"}}
    @models={{array "feature" "event"}}
    @space={{@space}}
  >
  
    <Editor::Properties::OptionContainer @label={{t "option-panel.attribute-path"}} as |id|>
      <Input::Text
        class="form-control input-attribute-field"
        id={{id}}
        @value={{this.attributeField}}
        @onChange={{fn this.change "attributeField"}}
      />
    </Editor::Properties::OptionContainer>
  
  </Input::Options::ModelItem>
  
  <Input::Options::Frame class="title-style" @title={{t "option-panel.title"}}>
    <Editor::Properties::Title
      @value={{this.title}}
      @deviceSize={{@deviceSize}}
      @onChange={{fn this.change "title"}}
      @enableTextValue={{true}}
    />
  </Input::Options::Frame>
  
  <Input::Options::Frame @title={{t "option-panel.attribute-style"}} class="attribute-style">
    <Editor::Properties::Text
      @enableTextValue={{false}}
      @value={{this.attributeStyle}}
      @onChange={{fn this.change "attributeStyle"}}
    />
  </Input::Options::Frame>
  */
  {
    "id": "qbgrjwWR",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@onChange\",\"@models\",\"@space\"],[[30,0,[\"source\"]],[28,[37,1],[[30,0,[\"change\"]],\"source\"],null],[28,[37,2],[\"feature\",\"event\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@label\"],[[28,[37,4],[\"option-panel.attribute-path\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"form-control input-attribute-field\"],[16,1,[30,2]]],[[\"@value\",\"@onChange\"],[[30,0,[\"attributeField\"]],[28,[37,1],[[30,0,[\"change\"]],\"attributeField\"],null]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],[[24,0,\"title-style\"]],[[\"@title\"],[[28,[37,4],[\"option-panel.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],null,[[\"@value\",\"@deviceSize\",\"@onChange\",\"@enableTextValue\"],[[30,0,[\"title\"]],[30,3],[28,[37,1],[[30,0,[\"change\"]],\"title\"],null],true]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],[[24,0,\"attribute-style\"]],[[\"@title\"],[[28,[37,4],[\"option-panel.attribute-style\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,8],null,[[\"@enableTextValue\",\"@value\",\"@onChange\"],[false,[30,0,[\"attributeStyle\"]],[28,[37,1],[[30,0,[\"change\"]],\"attributeStyle\"],null]]],null],[1,\"\\n\"]],[]]]]]],[\"@space\",\"id\",\"@deviceSize\"],false,[\"input/options/model-item\",\"fn\",\"array\",\"editor/properties/option-container\",\"t\",\"input/text\",\"input/options/frame\",\"editor/properties/title\",\"editor/properties/text\"]]",
    "moduleName": "spaces/components/editor/attribute.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let EditorAttributeComponent = _exports.default = (_class = class EditorAttributeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_source", _descriptor, this);
      _initializerDefineProperty(this, "_title", _descriptor2, this);
      _initializerDefineProperty(this, "_attributeStyle", _descriptor3, this);
      _initializerDefineProperty(this, "_attributeField", _descriptor4, this);
    }
    get source() {
      if (this._source) {
        return this._source;
      }
      return this.args.value?.data?.source ?? {};
    }
    get title() {
      if (this._title) {
        return this._title;
      }
      return this.args.value?.data?.title ?? {};
    }
    get attributeStyle() {
      if (this._attributeStyle) {
        return this._attributeStyle;
      }
      return this.args.value?.data?.attributeStyle ?? {};
    }
    get attributeField() {
      if (this._attributeField) {
        return this._attributeField;
      }
      return this.args.value?.data?.sourceAttribute?.field ?? "";
    }
    triggerChange() {
      return this.args.onChange?.({
        source: this.source,
        sourceAttribute: {
          field: this.attributeField
        },
        title: this.title,
        attributeStyle: this.attributeStyle
      });
    }
    change(key, value) {
      this[`_${key}`] = value;
      this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_source", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_attributeStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_attributeField", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorAttributeComponent);
});