define("manage/components/manage/time-tracking", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}} @onSave={{@onSave}} @value={{@value}} @onCancel={{@onCancel}} ...attributes>
    <:view>
      <TimeTrackingTable @value={{@value}} @emptyMessage={{@emptyMessage}} />
    </:view>
  
    <:edit as |manager|>
      <Input::TimeTrackingList @value={{manager.value}} @onChange={{manager.change}} />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "BF4ZdIuR",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@value\",\"@onCancel\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],[[\"view\",\"edit\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@emptyMessage\"],[[30,7],[30,9]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@onChange\"],[[30,10,[\"value\"]],[30,10,[\"change\"]]]],null],[1,\"\\n  \"]],[10]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@value\",\"@onCancel\",\"@emptyMessage\",\"manager\"],false,[\"manage/field-container\",\":view\",\"time-tracking-table\",\":edit\",\"input/time-tracking-list\"]]",
    "moduleName": "manage/components/manage/time-tracking.hbs",
    "isStrictMode": false
  });
  class ManageTimeTrackingComponent extends _component2.default {}
  _exports.default = ManageTimeTrackingComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageTimeTrackingComponent);
});