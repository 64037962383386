define("manage/components/manage/page-select", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{this.isDisabled}} @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}} @onSave={{this.save}} @onCancel={{this.cancel}} @value={{@value}} {{did-insert this.setup}} ...attributes>
    <:view>
      {{#if this.value}}
        <LinkTo @route='manage.pages.content' @model={{this.value.id}}>
          {{this.value.name}}
        </LinkTo>
      {{else}}
        <div class='alert {{if @required "alert-danger" "alert-info"}} ' role='alert'>
          {{t 'message-no-page'}}
        </div>
      {{/if}}
    </:view>
  
    <:edit>
      <Input::Select @allowEmpty={{not @required}} @list={{this.list}} @value={{this.value}} @onChange={{this.change}} />
    </:edit>
  
  </Manage::FieldContainer>
  */
  {
    "id": "G5P4DF0F",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[[30,0,[\"setup\"]]],null]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"@value\"],[[30,2],[30,0,[\"isDisabled\"]],[30,3],[30,4],[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,5]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,0,[\"value\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"manage.pages.content\",[30,0,[\"value\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"value\",\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"alert \",[52,[30,6],\"alert-danger\",\"alert-info\"],\" \"]]],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"message-no-page\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,8],null,[[\"@allowEmpty\",\"@list\",\"@value\",\"@onChange\"],[[28,[37,9],[[30,6]],null],[30,0,[\"list\"]],[30,0,[\"value\"]],[30,0,[\"change\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@editablePanel\",\"@onEdit\",\"@value\",\"@required\"],false,[\"manage/field-container\",\"did-insert\",\":view\",\"if\",\"link-to\",\"div\",\"t\",\":edit\",\"input/select\",\"not\"]]",
    "moduleName": "manage/components/manage/page-select.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let InputManagePageSelectComponent = _exports.default = (_class = class InputManagePageSelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "value", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get list() {
      return ['', ...(this.args.list ?? [])];
    }
    async setup() {
      if (!this.args.value) {
        return;
      }
      if (typeof this.args.value == 'object') {
        this.value = this.args.value;
      }
      if (this.args.isString) {
        this.value = this.store.peekRecord('page', this.args.value);
      }
      if (this.args.isString && !this.value) {
        this.value = await this.store.findRecord('page', this.args.value);
      }
    }
    change(value) {
      this.value = value;
    }
    save() {
      if (this.args.isString) {
        return this.args.onSave(this.value?.id);
      }
      return this.args.onSave(this.value);
    }
    cancel() {
      this.setup();
      return this.args.onCancel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputManagePageSelectComponent);
});