define("manage/components/input/link-pair", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row" ...attributes>
    <div class="col">
      <Input::Text
        class="form-control"
        @value={{@value.source}}
        @onChange={{fn this.change "source"}}
      />
    </div>
    <div class="col">
      <Input::Link @value={{@value.destination}} @onChange={{fn this.change "destination"}} @space={{@space}} />
    </div>
  </div>
  */
  {
    "id": "khdMarwx",
    "block": "[[[11,0],[24,0,\"row\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"form-control\"]],[[\"@value\",\"@onChange\"],[[30,2,[\"source\"]],[28,[37,2],[[30,0,[\"change\"]],\"source\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@value\",\"@onChange\",\"@space\"],[[30,2,[\"destination\"]],[28,[37,2],[[30,0,[\"change\"]],\"destination\"],null],[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\",\"@space\"],false,[\"div\",\"input/text\",\"fn\",\"input/link\"]]",
    "moduleName": "manage/components/input/link-pair.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let InputLinkPairComponent = _exports.default = (_class = class InputLinkPairComponent extends _component2.default {
    change(field, newValue) {
      let value = this.args.value;
      value[field] = newValue;
      return value;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputLinkPairComponent);
});