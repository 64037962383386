define("map/components/map-attributions", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.this.attributions}}
    <Map::Container
      class="attributions"
      @left={{true}}
      @bottom={{true}}
      @right={{true}}
    >
      {{#each this.attributions as |attribution|}}
        {{#if attribution.url}}
          <a
            target="_blank"
            href={{attribution.url}}
            rel="noopener noreferrer"
          >{{attribution.name}}</a>
        {{else}}
          <span>{{attribution.name}}</span>
        {{/if}}
      {{/each}}
    </Map::Container>
  {{/if}}
  */
  {
    "id": "+ZtgN1oC",
    "block": "[[[41,[30,0,[\"attributions\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"attributions\"]],[[\"@left\",\"@bottom\",\"@right\"],[true,true,true]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"attributions\"]]],null]],null],null,[[[41,[30,1,[\"url\"]],[[[1,\"        \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[30,1,[\"url\"]]],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]]]],[1]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"attribution\"],false,[\"if\",\"map/container\",\"each\",\"-track-array\",\"a\",\"span\"]]",
    "moduleName": "map/components/map-attributions.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class MapAttributionsComponent extends _component2.default {
    get attributions() {
      const list = [];
      if (this.args?.map?.license?.name) {
        list.push(this.args.map.license);
      }
      const attributions = this.args.baseMap?.get ? this.args.baseMap?.get('attributions') : this.args.baseMap?.attributions;
      for (const item of attributions ?? []) {
        list.push(item);
      }
      return list;
    }
  }
  _exports.default = MapAttributionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapAttributionsComponent);
});