define("manage/components/input/time-tracking-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "models/transforms/subscription-details", "@ember/template-factory"], function (_exports, _component, _component2, _object, _subscriptionDetails, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::ListWithAdd @plusLabel={{t "entry"}} @value={{@value}} @factoryValue={{this.factoryValue}} @onChange={{@onChange}} ...attributes as |value change|>
    <Input::TimeTracking @value={{value}} @onChange={{change}} />
  </Input::ListWithAdd>
  */
  {
    "id": "2+827P+3",
    "block": "[[[8,[39,0],[[17,1]],[[\"@plusLabel\",\"@value\",\"@factoryValue\",\"@onChange\"],[[28,[37,1],[\"entry\"],null],[30,2],[30,0,[\"factoryValue\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,4],[30,5]]],null],[1,\"\\n\"]],[4,5]]]]]],[\"&attrs\",\"@value\",\"@onChange\",\"value\",\"change\"],false,[\"input/list-with-add\",\"t\",\"input/time-tracking\"]]",
    "moduleName": "manage/components/input/time-tracking-list.hbs",
    "isStrictMode": false
  });
  let InputTimeTrackingListComponent = _exports.default = (_class = class InputTimeTrackingListComponent extends _component2.default {
    factoryValue() {
      return new _subscriptionDetails.TimeTracking({
        hours: 1,
        date: new Date().toISOString()
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "factoryValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "factoryValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputTimeTrackingListComponent);
});