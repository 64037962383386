define("manage/components/manage/map", ["exports", "@ember/component", "@glimmer/component", "@ember/object/internals", "@glimmer/tracking", "@ember/object", "models/transforms/optional-map", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _internals, _tracking, _object, _optionalMap, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{@isDisabled}}
    @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    ...attributes
  >
    <:view>
      {{#if (and @value @value.isEnabled)}}
        <h4>{{t this.enabledText}}</h4>
        <LinkTo @route="manage.dashboards.map" @model={{@value.map.id}}>{{@value.map.name}}</LinkTo>
      {{else}}
        <div class="alert alert-info" role="alert">
          <MarkDown @value={{t this.disabledText}} />
        </div>
      {{/if}}
    </:view>
  
    <:edit>
      <div class="form-check form-switch pb-2">
        <Input
          @type="checkbox"
          @checked={{this.isEnabled}}
          id="{{this.elementId}}Enable"
          class="form-check-input enable-map"
        />
        <label class="form-check-label" for="{{this.elementId}}Enable">{{t "enabled"}}</label>
      </div>
  
      {{#if this.isEnabled}}
        <Input::Select class="map-select" @list={{@maps}} @value={{this.map}} @onChange={{this.updateMap}} />
      {{/if}}
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "85QNOwmY",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,0,[\"save\"]],[30,0,[\"cancel\"]]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[30,6],[30,6,[\"isEnabled\"]]],null],[[[1,\"      \"],[10,\"h4\"],[12],[1,[28,[35,5],[[30,0,[\"enabledText\"]]],null]],[13],[1,\"\\n      \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"manage.dashboards.map\",[30,6,[\"map\",\"id\"]]]],[[\"default\"],[[[[1,[30,6,[\"map\",\"name\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[8,[39,8],null,[[\"@value\"],[[28,[37,5],[[30,0,[\"disabledText\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"form-check form-switch pb-2\"],[12],[1,\"\\n      \"],[8,[39,10],[[16,1,[29,[[30,0,[\"elementId\"]],\"Enable\"]]],[24,0,\"form-check-input enable-map\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"isEnabled\"]]]],null],[1,\"\\n      \"],[10,\"label\"],[14,0,\"form-check-label\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"Enable\"]]],[12],[1,[28,[35,5],[\"enabled\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isEnabled\"]],[[[1,\"      \"],[8,[39,12],[[24,0,\"map-select\"]],[[\"@list\",\"@value\",\"@onChange\"],[[30,7],[30,0,[\"map\"]],[30,0,[\"updateMap\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@value\",\"@maps\"],false,[\"manage/field-container\",\":view\",\"if\",\"and\",\"h4\",\"t\",\"link-to\",\"div\",\"mark-down\",\":edit\",\"input\",\"label\",\"input/select\"]]",
    "moduleName": "manage/components/manage/map.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageMapComponent = _exports.default = (_class = class ManageMapComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _defineProperty(this, "elementId", 'map-' + (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "_isEnabled", _descriptor2, this);
      _initializerDefineProperty(this, "_map", _descriptor3, this);
    }
    get isDisabled() {
      return typeof this._isEnabled != 'boolean' && !this._map;
    }
    get parentModel() {
      return this.args.parentModel ?? 'survey';
    }
    get enabledText() {
      if (this.args.enabledText) {
        return this.args.enabledText;
      }
      return `manage-section.map-${this.parentModel}`;
    }
    get disabledText() {
      if (this.args.disabledText) {
        return this.args.disabledText;
      }
      return `manage-section.no-map-${this.parentModel}`;
    }
    get isEnabled() {
      if (this._isEnabled !== null && this._isEnabled !== undefined) {
        return this._isEnabled;
      }
      if (!this.args.value || !this.args.value.isEnabled) {
        return false;
      }
      return this.args.value.isEnabled;
    }
    set isEnabled(value) {
      this._isEnabled = value;
    }
    get map() {
      if (this._map) {
        return this._map;
      }
      if (!this.args.value || !this.args.value.map) {
        return null;
      }
      return this.args.value.map;
    }
    updateMap(map) {
      this._map = map;
    }
    save() {
      if (!this.args.onSave) {
        return;
      }
      return this.args.onSave(new _optionalMap.OptionalMap({
        isEnabled: this.isEnabled,
        map: this.map
      }, this.store));
    }
    cancel() {
      this._map = null;
      this._isEnabled = null;
      if (this.args.onCancel) {
        return this.args.onCancel();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_map", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageMapComponent);
});