define("ember-cli-fastboot/locations/none", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/application", "@ember/routing/none-location"], function (_exports, _object, _computed, _service, _application, _noneLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-get, ember/require-computed-property-dependencies, prettier/prettier */

  const TEMPORARY_REDIRECT_CODE = 307;
  var _default = _exports.default = _noneLocation.default.extend({
    implementation: 'fastboot',
    fastboot: (0, _service.inject)(),
    _config: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }),
    _fastbootHeadersEnabled: (0, _computed.bool)('_config.fastboot.fastbootHeaders'),
    _redirectCode: (0, _object.computed)(function () {
      return (0, _object.get)(this, '_config.fastboot.redirectCode') || TEMPORARY_REDIRECT_CODE;
    }),
    _response: (0, _computed.readOnly)('fastboot.response'),
    _request: (0, _computed.readOnly)('fastboot.request'),
    setURL(path) {
      if ((0, _object.get)(this, 'fastboot.isFastBoot')) {
        let response = (0, _object.get)(this, '_response');
        let currentPath = (0, _object.get)(this, 'path');
        let isInitialPath = !currentPath || currentPath.length === 0;
        if (!isInitialPath) {
          path = this.formatURL(path);
          let isTransitioning = currentPath !== path;
          if (isTransitioning) {
            let host = (0, _object.get)(this, '_request.host');
            let redirectURL = `//${host}${path}`;
            response.statusCode = this.get('_redirectCode');
            response.headers.set('location', redirectURL);
          }
        }

        // for testing and debugging
        if ((0, _object.get)(this, '_fastbootHeadersEnabled')) {
          response.headers.set('x-fastboot-path', path);
        }
      }
      this._super(...arguments);
    }
  });
});