define("spaces-view/modifiers/editor-props", ["exports", "@ember/modifier"], function (_exports, _modifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  var _default = _exports.default = (0, _modifier.setModifierManager)(() => ({
    capabilities: (0, _modifier.capabilities)('3.22', {
      disableAutoTracking: false
    }),
    createModifier() {},
    installModifier(_state, element, {
      positional: [value, ...args],
      named
    }) {
      if (!value) {
        return;
      }
      element.dataset.name = value.name;
      element.dataset.gid = value.gid;
      if (named.basePath) {
        element.dataset.path = [...named.basePath.split('.'), 'data', ...named.property.split('.')].filter(a => a).join('.');
      }
    },
    updateModifier() {},
    destroyModifier() {}
  }), class DidInsertModifier {});
});