define("manage/components/manage/schedule", ["exports", "@ember/component", "manage/components/manage/calendar-entry-list", "@ember/template-factory"], function (_exports, _component, _calendarEntryList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}}
    @onSave={{this.save}}
    @onCancel={{this.cancel}}
    @value={{@value}}
    ...attributes
  >
    <:view>
      <Schedule @value={{this.value}} />
    </:view>
  
    <:edit>
      <Input::Schedule @value={{this.value}} @onChange={{this.change}} />
    </:edit>
  
  </Manage::FieldContainer>
  */
  {
    "id": "1tAE30xO",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"@value\"],[[30,2],[30,0,[\"isDisabled\"]],[30,3],[30,4],[30,0,[\"save\"]],[30,0,[\"cancel\"]],[30,5]]],[[\"view\",\"edit\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[30,0,[\"change\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@editablePanel\",\"@onEdit\",\"@value\"],false,[\"manage/field-container\",\":view\",\"schedule\",\":edit\",\"input/schedule\"]]",
    "moduleName": "manage/components/manage/schedule.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class InputManageScheduleComponent extends _calendarEntryList.default {}
  _exports.default = InputManageScheduleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InputManageScheduleComponent);
});