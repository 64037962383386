define("manage/components/manage/survey-questions", ["exports", "@ember/component", "@glimmer/component", "@ember/array", "@ember/template-factory"], function (_exports, _component, _component2, _array, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}} @onSave={{@onSave}} @value={{this.questions}} @onCancel={{@onCancel}} ...attributes>
    <:view>
      {{#if this.viewQuestions}}
        <QuestionsView @value={{this.viewQuestions}} />
      {{else}}
        <div class="alert alert-warning" role="alert">
          {{t "message.no-survey-questions"}}
        </div>
      {{/if}}
    </:view>
  
    <:edit as |manager|>
      <Input::Questions @value={{manager.value}} @onChange={{manager.change}} @iconSets={{@iconSets}} />
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "lQMHZWIY",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@value\",\"@onCancel\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,0,[\"questions\"]],[30,7]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,0,[\"viewQuestions\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@value\"],[[30,0,[\"viewQuestions\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-warning\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"message.no-survey-questions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],null,[[\"@value\",\"@onChange\",\"@iconSets\"],[[30,8,[\"value\"]],[30,8,[\"change\"]],[30,9]]],null],[1,\"\\n  \"]],[8]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"manager\",\"@iconSets\"],false,[\"manage/field-container\",\":view\",\"if\",\"questions-view\",\"div\",\"t\",\":edit\",\"input/questions\"]]",
    "moduleName": "manage/components/manage/survey-questions.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class ManageSurveyQuestionsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "fields", ['sounds', 'pictures', 'icons', 'description', 'name', 'position']);
    }
    get value() {
      return this.args.value ?? [];
    }
    get viewQuestions() {
      return this.questions.filter(a => a?.isVisible);
    }
    get questions() {
      const result = (0, _array.A)(this.value.map(a => this.mapQuestion(a)));
      for (const name of this.fields) {
        if (!result.find(a => a.name === name)) {
          result.insertAt(0, this[`${name}Question`]);
        }
      }
      return result;
    }
    mapQuestion(question) {
      for (const name of this.fields) {
        if (question.name == name) {
          return this[`${name}Question`];
        }
      }
      return question;
    }
    defaultQuestion(name, type, question) {
      const item = this.args.value?.find(a => a.name == name) ?? {};
      const exists = !!this.args.value?.find(a => a.name == name);
      return {
        question: item.question ?? question,
        help: item.help,
        type,
        name,
        isRequired: exists ? item.isRequired : false,
        isVisible: exists ? item.isVisible : false,
        options: item.options,
        disableDelete: true,
        hasVisibility: true
      };
    }
    get positionQuestion() {
      return this.defaultQuestion('position', 'geo json', 'Location');
    }
    get nameQuestion() {
      return this.defaultQuestion('name', 'short text', "What's the name of this place?");
    }
    get descriptionQuestion() {
      return this.defaultQuestion('description', 'long text', 'What do you like about this place?');
    }
    get iconsQuestion() {
      return this.defaultQuestion('icons', 'icons', 'Please select an icon from the list');
    }
    get picturesQuestion() {
      return this.defaultQuestion('pictures', 'pictures', 'Pictures');
    }
    get soundsQuestion() {
      return this.defaultQuestion('sounds', 'sounds', 'Sounds');
    }
  }
  _exports.default = ManageSurveyQuestionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSurveyQuestionsComponent);
});