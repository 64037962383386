define("manage/templates/manage/newsletters/import", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title @model.newsletter.name " | " (capitalize (t "import emails"))}}
  
  {{#if (or this.fastboot.isFastBoot (not @model))}}
    <LoadingContainer />
  {{else}}
    <div class="d-flex min-h-100">
      <Manage::Container @title={{capitalize (t "import emails")}} @breadcrumbs={{this.breadcrumbs}}>
        <:content>
          <Manage::ImportEmails @newsletter={{@model.newsletter}} @onSave={{this.onSave}} />
        </:content>
      </Manage::Container>
    </div>
  {{/if}}
  */
  {
    "id": "USXwWc56",
    "block": "[[[1,[28,[35,0],[[30,1,[\"newsletter\",\"name\"]],\" | \",[28,[37,1],[[28,[37,2],[\"import emails\"],null]],null]],null]],[1,\"\\n\\n\"],[41,[28,[37,4],[[30,0,[\"fastboot\",\"isFastBoot\"]],[28,[37,5],[[30,1]],null]],null],[[[1,\"  \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"d-flex min-h-100\"],[12],[1,\"\\n    \"],[8,[39,8],null,[[\"@title\",\"@breadcrumbs\"],[[28,[37,1],[[28,[37,2],[\"import emails\"],null]],null],[30,0,[\"breadcrumbs\"]]]],[[\"content\"],[[[[1,\"\\n        \"],[8,[39,10],null,[[\"@newsletter\",\"@onSave\"],[[30,1,[\"newsletter\"]],[30,0,[\"onSave\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@model\"],false,[\"page-title\",\"capitalize\",\"t\",\"if\",\"or\",\"not\",\"loading-container\",\"div\",\"manage/container\",\":content\",\"manage/import-emails\"]]",
    "moduleName": "manage/templates/manage/newsletters/import.hbs",
    "isStrictMode": false
  });
});