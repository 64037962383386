define("spaces/components/editor/source-record", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::ModelItem
    @value={{@value}}
    @onChange={{@onChange}}
    @space={{@space}}
    @preferredModel={{this.preferredModel}}
    @withPicture={{this.withPicture}}
    @pageId={{@model.page.id}}
    ...attributes
  />
  */
  {
    "id": "RTp8TrXb",
    "block": "[[[8,[39,0],[[17,1]],[[\"@value\",\"@onChange\",\"@space\",\"@preferredModel\",\"@withPicture\",\"@pageId\"],[[30,2],[30,3],[30,4],[30,0,[\"preferredModel\"]],[30,0,[\"withPicture\"]],[30,5,[\"page\",\"id\"]]]],null]],[\"&attrs\",\"@value\",\"@onChange\",\"@space\",\"@model\"],false,[\"input/options/model-item\"]]",
    "moduleName": "spaces/components/editor/source-record.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorSourceRecordComponent extends _component2.default {
    get withPicture() {
      return this.args.element?.attributes?.getNamedItem?.("data-with-picture")?.value.toLowerCase?.() == "true";
    }
    get preferredModel() {
      return this.args.element?.attributes?.getNamedItem?.("data-preferred-model")?.value.toLowerCase?.() || this.args.preferredModel;
    }
  }
  _exports.default = EditorSourceRecordComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorSourceRecordComponent);
});