define("manage/routes/manage/teams/subscription", ["exports", "manage/routes/base/authenticated-route", "rsvp"], function (_exports, _authenticatedRoute, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class ManageTeamsSubscriptionRoute extends _authenticatedRoute.default {
    model(params) {
      return (0, _rsvp.hash)({
        team: this.store.findRecord('team', params.id)
      });
    }
  }
  _exports.default = ManageTeamsSubscriptionRoute;
});